import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-232dab88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container p_rboot_manage"
};
const _hoisted_2 = {
  class: "top-container searchTop"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  key: 0,
  class: "button-container"
};
const _hoisted_5 = {
  class: "drawer-title"
};
const _hoisted_6 = {
  class: "group-unit"
};
const _hoisted_7 = {
  class: "group-name"
};
const _hoisted_8 = {
  class: "group-phone"
};
const _hoisted_9 = {
  class: "group-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_commonForm = _resolveComponent("commonForm");
  const _component_el_button = _resolveComponent("el-button");
  const _component_commonTable = _resolveComponent("commonTable");
  const _component_commonDrawer = _resolveComponent("commonDrawer");
  const _component_commonChatGroupHistory = _resolveComponent("commonChatGroupHistory");
  const _directive_hasPermi = _resolveDirective("hasPermi");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode(" 查询区域\\操作按钮区域 "), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_commonForm, {
    form: $setup.state.queryParams,
    formData: $setup.state.searchFormData,
    formHandle: $setup.state.formHandle
  }, null, 8 /* PROPS */, ["form", "formData", "formHandle"]), _createCommentVNode(" 操作按钮 "), $setup.state.buttonsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.buttonsList, (item, index) => {
    return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
      key: index,
      class: _normalizeClass([item.className]),
      loading: item.loading,
      type: item.buttonStyle,
      onClick: $event => item.exp(item)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.buttonName), 1 /* TEXT */)]),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "loading", "type", "onClick"])), [[_directive_hasPermi, item.hasPermi ? item.hasPermi : []]]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])]), _createCommentVNode(" 表格 "), _withDirectives(_createVNode(_component_commonTable, {
    class: "flex-table",
    tableData: $setup.state.list,
    tableHeader: $setup.state.tableHeader,
    queryParams: $setup.state.queryParams,
    multipleSelection: $setup.state.ids,
    "onUpdate:multipleSelection": _cache[0] || (_cache[0] = $event => $setup.state.ids = $event),
    onGetList: $setup.getList,
    hasSelect: false,
    columnSetting: false,
    onSelectionChange: _ctx.handleSelectionChange
  }, null, 8 /* PROPS */, ["tableData", "tableHeader", "queryParams", "multipleSelection", "onSelectionChange"]), [[_directive_loading, $setup.state.loading]]), _createCommentVNode(" 弹窗 "), _createVNode(_component_commonDrawer, {
    modelValue: $setup.state.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.state.dialogVisible = $event),
    size: "30%",
    title: "查看绑定微信群信息",
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [_createVNode(_component_commonForm, {
      form: $setup.state.listQueryParams,
      formData: $setup.state.searchListFormData,
      formHandle: $setup.state.listFormHandle,
      buttonsList: [],
      labelPosition: "left"
    }, null, 8 /* PROPS */, ["form", "formData", "formHandle"]), _withDirectives(_createVNode(_component_commonTable, {
      class: "flex-table",
      tableData: $setup.state.manageList,
      tableHeader: $setup.state.listTableHeader,
      queryParams: $setup.state.listQueryParams,
      onGetList: $setup.getManageList,
      hasSelect: false,
      columnSetting: false
    }, null, 8 /* PROPS */, ["tableData", "tableHeader", "queryParams"]), [[_directive_loading, $setup.state.listLoading]])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_commonDrawer, {
    modelValue: $setup.state.groupTalkHistoryDialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.state.groupTalkHistoryDialogVisible = $event),
    size: "50%",
    title: "查看群聊记录",
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, "单位：" + _toDisplayString($setup.state.groupBasicInfo.unit), 1 /* TEXT */), _createElementVNode("div", _hoisted_7, "客户经理：" + _toDisplayString($setup.state.groupBasicInfo.person), 1 /* TEXT */), _createElementVNode("div", _hoisted_8, "客户经理电话：" + _toDisplayString($setup.state.groupBasicInfo.phone), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_9, _toDisplayString($setup.state.groupBasicInfo.groupName), 1 /* TEXT */), _createVNode(_component_commonChatGroupHistory, {
      needSearch: true,
      onGetSearchInfo: $setup.getSearchInfo,
      infoList: $setup.state.infoList,
      loading: $setup.state.infoListLoading
    }, null, 8 /* PROPS */, ["infoList", "loading"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])]);
}