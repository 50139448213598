import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as courtsApi from '@/api/courtsManage';
import * as weChatApi from '@/api/weChatManage';
import { download } from '@/utils/index';
import { Decrypt, Encrypt } from '@/utils/cryptojs';
// import commonDrawer from './commonDrawer.vue'

export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 遮罩层
      loading: true,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 模型表格数据
      list: {
        rows: [],
        total: 0
      },
      currentSelectRows: [],
      dialogVisible: false,
      // 表单参数
      form: {
        id: '',
        courtsManagePhone: '',
        courts_remarkname_robot: ''
      },
      // 表单校验
      rules: {
        courtsManagePhone: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        courtsRemarkNameRobot: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }]
      },
      formData: [{
        prop: "courtsManagePhone",
        label: "手机号码",
        placeholder: "请输入手机号码",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "courtsRemarkNameRobot",
        label: "微信名称",
        placeholder: "请输入微信名称",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }],
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        courtsNo: null,
        fourthDept: '',
        deptName: '',
        lineName: '',
        courtsName: '',
        lineId: '',
        city: '',
        secondDept: '',
        courtsManageNames: [],
        courtType: ''
      },
      isExport: false,
      roleAdmin: false,
      rolejzbl: false,
      cloneQueryParams: null,
      searchFormData: [{
        prop: "secondDept",
        label: "所属地市",
        type: "bigData_list_multi",
        placeholder: "请选择所属地市",
        multiple: false,
        clearable: 'no',
        span: 7,
        minWidth: 270,
        disabledHandle(row) {
          return !state.roleAdmin; //可操作，其余禁用
        },

        options: [],
        props: {
          label: 'secondDept',
          value: 'secondDept'
        },
        change: val => {
          let queryParams = {
            secondDept: val
          };
          state.queryParams.city = '';
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getCityList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "city",
        label: "所属区域",
        clearable: 'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        type: "bigData_list_multi",
        placeholder: "请选择所属区域",
        multiple: false,
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          let queryParams = {
            city: val,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getDeptList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "deptName",
        label: "所属单位",
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        clearable: 'no',
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          let queryParams = {
            deptName: val,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "fourthDept",
        label: "所属班所",
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属班所",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'fourthDept',
          value: 'fourthDept'
        },
        change: val => {
          let queryParams = {
            fourthDept: val,
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getLinesList(queryParams);
          getCoutrsManage(queryParams);
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "lineName",
        label: "所属线路",
        type: "bigData_list_multi",
        placeholder: "请输入所属线路",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'lineName',
          value: 'lineId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.lineId === val);
          if (selectedOption) {
            state.queryParams.lineId = val;
            state.queryParams.lineName = selectedOption.lineName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName
            // lineId:val
          };

          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsName",
        label: "台区名称",
        type: "bigData_list_multi",
        placeholder: "请输入台区名称",
        multiple: false,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsName',
          value: 'courtsId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.courtsId === val);
          if (selectedOption) {
            state.queryParams.courtsId = val;
            state.queryParams.courtsName = selectedOption.courtsName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName,
            // lineId:state.queryParams.lineId,
            courtsName: state.queryParams.courtsName
            // courtsId:val
          };

          state.queryParams.courtsManageNames = [];
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsManageNames",
        label: "台区经理",
        type: "bigData_list_multi",
        placeholder: "请输入台区经理",
        multiple: true,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsManageName',
          value: 'courtsManageName'
        },
        change: (val, control) => {
          // const selectedOption = control.find((option) => option.courtsId === val);
          // state.queryParams.courtsId=val
          // state.queryParams.courtsName=selectedOption.courtsName
        },
        queryParams: {}
      }, {
        prop: "courtsNo",
        label: "台区编号",
        placeholder: "请输入台区编号查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "courtType",
        label: "台区类型",
        type: "list",
        placeholder: "请选择台区类型",
        span: 7,
        minWidth: 200,
        options: [{
          dictLabel: '公变',
          dictValue: '公变'
        }, {
          dictLabel: '专变',
          dictValue: '专变'
        }]
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "编辑",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleEdit()
      }, {
        buttonName: "同步",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => synchronous()
      }, {
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号",
        width: '50px'
      }, {
        prop: "courtsNo",
        label: "台区编号"
      }, {
        prop: "courtsName",
        label: "台区名称"
      }, {
        prop: "courtType",
        label: "台区类型"
      }, {
        prop: "courtsManageName",
        label: "台区经理"
      }, {
        prop: "courtsManagePhone",
        label: "联系电话"
      }, {
        prop: "city",
        label: "所属区域"
      }, {
        prop: "deptName",
        label: "所属单位"
      }, {
        prop: "lineName",
        label: "所属线路"
      }]
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    onMounted(() => {
      if (sessionStorage.getItem('isAdmin') == "false") {
        state.roleAdmin = false;
        let roleInfo = sessionStorage.getItem('roleInfo');
        let currentDept = sessionStorage.getItem('currentDept');
        // wgwxbsz班所  wgwxkhjl客户经理  wgwxfwzz专责 --currentDept--接口 根据单位反查currentDept
        // wgwxjzbl省市集中办理--currentDept--市
        if (roleInfo == "wgwxkhjl" || roleInfo == "wgwxfwzz" || roleInfo == "wgwxbsz") {
          state.rolejzbl = false;
          state.queryParams.deptName = currentDept;
          weChatApi.formSecondDept({
            currentDept: currentDept
          }).then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            let queryParams = {
              secondDept: res.data[0].secondDept,
              currentDept: currentDept
            };
            weChatApi.formCity(queryParams).then(res => {
              state.searchFormData[1].options = res.data || [];
              state.queryParams.city = res.data[0].city;
              let queryParams = {
                deptName: state.queryParams.deptName,
                city: state.queryParams.city,
                secondDept: state.queryParams.secondDept
              };
              getFourtList(queryParams);
              getLinesList(queryParams);
              getCourtsList(queryParams);
              getCoutrsManage(queryParams);
              getList();
            });
          });
        }
        if (roleInfo == "wgwxjzbl") {
          state.rolejzbl = true;
          weChatApi.formSecondDept().then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            getCityList1({
              secondDept: res.data[0].secondDept
            });
            // getList()
          });
        }
      } else {
        state.roleAdmin = true;
        state.rolejzbl = true;
        getSecondDeptList();
        // getList()
      }
    });

    const getSecondDeptList = function (params = {}) {
      weChatApi.formSecondDept(params).then(res => {
        state.searchFormData[0].options = res.data || [];
        if (!state.queryParams.secondDept) {
          state.queryParams.secondDept = res.data[0].secondDept;
          getCityList1({
            secondDept: state.queryParams.secondDept
          });
        }
      });
    };
    const getCityList1 = function (params = {}) {
      weChatApi.formCity(params).then(res => {
        state.searchFormData[1].options = res.data || [];
        if (!state.queryParams.city) {
          state.queryParams.city = res.data[0].city;
          getDeptList1({
            city: state.queryParams.city
          });
        }
      });
    };
    const getDeptList1 = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.searchFormData[2].options = res.data || [];
        if (!state.queryParams.deptName) {
          state.queryParams.deptName = res.data[0].deptName;
          let queryParams = {
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          getList(state.queryParams);
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        }
      });
    };
    const getFourtList = function (params = {}) {
      weChatApi.formFourth(params).then(res => {
        state.searchFormData[3].options = res.data || [];
      });
    };
    const getLinesList = function (params = {}) {
      weChatApi.formLines(params).then(res => {
        state.searchFormData[4].options = res.data || [];
      });
    };
    // 查询台区下拉
    const getCourtsList = function (params = {}) {
      weChatApi.formCourts(params).then(res => {
        state.searchFormData[5].options = res.data || [];
      });
    };
    const getCoutrsManage = function (params = {}) {
      weChatApi.formCoutrsManage(params).then(res => {
        state.searchFormData[6].options = res.data || [];
      });
    };

    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      courtsApi.courtsList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.total = res.data.total || 0;
          state.list.rows = res.data.records || [];
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            let courtsManageName = state.list.rows[i]['courtsManageName'];
            if (courtsManageName != null && courtsManageName != '') {
              state.list.rows[i]['courtsManageName'] = Decrypt(courtsManageName);
            }
            let courtsManagePhone = state.list.rows[i]['courtsManagePhone'];
            if (courtsManagePhone != null && courtsManagePhone != '') {
              state.list.rows[i]['courtsManagePhone'] = Decrypt(courtsManagePhone);
            }
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };
    const handleSelectionChange = function (val) {
      state.currentSelectRows = val;
      if (val.length === 1) {
        state.buttonsList[0].disabled = false;
      } else {
        state.buttonsList[0].disabled = true;
      }
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      courtsApi.courtsExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '台区列表.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    const handleEdit = function () {
      state.form = JSON.parse(JSON.stringify(state.currentSelectRows[0]));
      state.dialogVisible = true;
      // hack方式处理编辑多次，表单校验不清除的问题
      setTimeout(() => {
        const formRef = unref(formContainer);
        formRef.commonForm.clearValidate();
      }, 0);
    };
    const synchronous = function () {
      courtsApi.courtsSynchronous().then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          ElMessage.success('数据同步服务已启动，请稍后刷新重试！');
        } else {
          ElMessage.closeAll();
          ElMessage.error(res.message);
        }
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.queryParams.pageNum = 1;
      getSecondDeptList();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          if (state.form.courtsManagePhone != null && state.form.courtsManagePhone != '') {
            state.form.courtsManagePhone = Encrypt(state.form.courtsManagePhone);
          }
          courtsApi.courtsUpdatePhone(state.form).then(res => {
            if (res.code === 1000) {
              ElMessage.closeAll();
              ElMessage.success('编辑成功');
              clearForm();
              resetQuery();
            } else {
              ElMessage.closeAll();
              ElMessage.error(res.message);
            }
          });
        }
      });
    };
    const clearForm = function () {
      state.dialogVisible = false;
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      getSecondDeptList,
      getCityList1,
      getDeptList1,
      getFourtList,
      getLinesList,
      getCourtsList,
      getCoutrsManage,
      getList,
      handleSelectionChange,
      downloadExport,
      handleEdit,
      synchronous,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get courtsApi() {
        return courtsApi;
      },
      get weChatApi() {
        return weChatApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      },
      get Encrypt() {
        return Encrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};