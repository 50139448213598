/*
 * @Author: yyl
 * @Date: 2021-07-21 13:59:34
 * @LastEditTime: 2021-08-10 14:58:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \energy-internet-web\src\utils\aiRequest.js
 */
import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import { getToken } from './auth.js'
import { setRequestUrl, setRequestBody } from './setHeader'
const whiteList=['/wechatGroup/deleteByids','/information/deleteByids','/personnelTopo/deleteBatch','/entry/delete','/entry/deleteContent','/area/delete']
export let axiosTemplate = (baseURL) => {
  axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
  // 创建axios实例
  const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL,
    // 超时
    timeout: 1000 * 60,
  })
  // request拦截器
  service.interceptors.request.use(
    (config) => {
      if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      }
      if(!whiteList.includes(config.url)&&((config.method==='post'&&sessionStorage.getItem('currentName')&&sessionStorage.getItem('currentDept'))||sessionStorage.getItem('isAdmin'))){
        if(sessionStorage.getItem('isAdmin')!=='false'){
          config.data={...config.data,...{
            currentName:'',
            currentDept:'',
            isAdmin:'1',
            roleInfo:'',
            currentISCCode:'',
            orgRoleInfo:''
          }}
        }else{
          config.data={...config.data,...{
            currentName:sessionStorage.getItem('currentName'),
            currentDept:sessionStorage.getItem('currentDept'),
            isAdmin:'',
            roleInfo:sessionStorage.getItem('roleInfo'),
            currentISCCode:sessionStorage.getItem('currentISCCode'),
            orgRoleInfo:sessionStorage.getItem('orgRoleInfo')
          }}
        }
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      const code = res.data.code
      // 判断是否有code存在，兼容导出时返回整个文件流
      if (code) {
        if ((code === 401 || code === '401')) {
          ElMessageBox.close()
          ElMessageBox.confirm(
            '登录状态已过期，您可以继续留在该页面，或者重新登录',
            '系统提示',
            {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning',
            },
          ).then(() => {
            store.dispatch("common/LogOut").then(() => {
              location.reload();
            });
          }).catch(()=>{

          })
          return res.data
        } if (code !== 1000) {
          if (code === 1001) {
            ElMessage.closeAll()
            ElMessage({
              type: 'warning',
              message: res.data.message,
            })
            return  Promise.resolve(res.data)
            //return Promise.reject(res.data.message)
          } else if (code === 1002) {
            ElMessage.closeAll()
            return res.data
          } else {
            ElMessage.closeAll()
            ElMessage({
              message: res.data.data||res.data.message || '系统异常，请重新登录',
              type: 'error',
              duration: 3 * 1000,
            })
          }

          return Promise.reject('error')
        } else {
          return res.data
        }
      } else {
        // code不存在时返回后端return对象
        return res
      }
    },
    // (error) => {
    //   console.log('err',error)
    //   ElMessage.closeAll()
    //   if (error.response.status === 401) {
    //     console.log('66666666')
    //       ElMessageBox.close()
    //       ElMessageBox.confirm(
    //         '登录状态已过期，您可以继续留在该页面，或者重新登录',
    //         '系统提示',
    //         {
    //           confirmButtonText: '重新登录',
    //           cancelButtonText: '取消',
    //           type: 'warning',
    //         },
    //       ).then(() => {
    //         store.dispatch('user/ACTION_LOGIN_OUT');
    //       })
    //   } else {
    //     ElMessage({
    //       message: error.message,
    //       type: 'error',
    //       duration: 5 * 1000,
    //     })
    //     return Promise.reject(error)
    //   }
    // },
  )

  return service
}
