import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    ref: "scrollContainerRef",
    vertical: false,
    class: "scroll-container",
    onWheel: _withModifiers(_ctx.handleScroll, ["prevent"])
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onWheel"]);
}