import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as weChatApi from '@/api/weChatManage';
import * as courtsPersonTopoApi from '@/api/courtsPersonTopo';
import { download } from '@/utils/index';
import { Decrypt, Encrypt } from '@/utils/cryptojs';
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      activeName: '一级管理人员',
      // 遮罩层
      loading: true,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 表单下拉关联参数
      formListParams: {
        city: '',
        deptName: ''
      },
      // 表格选中的数据
      currentSelectRows: [],
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        leaderName: '',
        leaderNickname: '',
        courtsManageNameStr: '',
        iscAdCode: '',
        leaderType: ''
      },
      cloneQueryParams: null,
      searchFormData: [{
        prop: "courtsManageNameStr",
        label: "台区经理名称",
        placeholder: "请输入台区经理名称",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px',
        hideHandle: () => {
          return state.activeName !== '二级管理人员';
        }
      }, {
        prop: "leaderName",
        label: "领导名称",
        placeholder: "请输入领导名称",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "leaderNickname",
        label: "领导昵称",
        placeholder: "请输入领导昵称",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "iscAdCode",
        label: "ISC用户账号",
        placeholder: "请输入ISC用户账号",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "leaderType",
        label: "人员类型",
        type: "list",
        placeholder: "请选择人员类型",
        span: 7,
        options: [{
          dictLabel: '区县专责',
          dictValue: '区县专责'
        }],
        hideHandle: () => {
          return state.activeName !== '一级管理人员';
        }
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: () => handleAdd()
      }, {
        buttonName: "编辑",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleEdit()
      }, {
        buttonName: "删除",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleDel()
      }, {
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "courtsManageNameStr",
        label: "台区经理",
        isShow: false
      }, {
        prop: "courtsManagePhone",
        label: "台区经理联系电话",
        isShow: false
      }, {
        prop: "city",
        label: "所属区县"
      }, {
        prop: "deptName",
        label: "所属单位"
      }, {
        prop: "leaderName",
        label: "领导名称"
      }, {
        prop: "leaderPhone",
        label: "领导联系电话"
      }, {
        prop: "leaderNickname",
        label: "领导微信昵称"
      }, {
        prop: "leaderType",
        label: "人员类型"
      }, {
        prop: "iscAdCode",
        label: "ISC用户账号"
      }, {
        prop: "discadCode",
        label: "班所长ISC用户账号"
      }],
      operation: '新增',
      //操作标志位
      transName: '',
      transformer: '',
      // 表单参数
      form: {
        city: null,
        deptName: null,
        courtsManageName: null,
        courtsManageNameStr: null,
        leaderName: null,
        leaderPhone: null,
        leaderNickname: null,
        leaderType: null,
        courtsIscId: null,
        courtsManageRows: []
      },
      // 表单校验
      rules: {
        deptName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        city: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        courtsManageName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        leaderName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        //leaderPhone: [{ required: true, message: "不能为空", trigger: "change" }],
        leaderPhone: [{
          validator: (rule, value, callback) => {
            if (value) {
              if (!value.toString().trim().match(/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/)) {
                callback(new Error('只能输入正确的手机号或者座机号！'));
              } else {
                callback();
              }
            } else {
              callback(new Error('不能为空'));
            }
          },
          trigger: "change"
        }],
        leaderNickname: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        iscAdCode: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        leaderType: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        courtsIscId: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }]
      },
      formData: [{
        prop: "city",
        label: "所属区县",
        type: "bigData_list_multi",
        placeholder: "请选择所属区县",
        span: 24,
        options: [],
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          state.form.deptName = null;
          state.form.courtsManageName = null;
          state.form.courtsManagePhone = '';
          state.formListParams = {
            city: val
          };
          getDeptList(state.formListParams);
        }
      }, {
        prop: "deptName",
        label: "所属单位",
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 24,
        options: [],
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          state.form.courtsManageName = null;
          state.form.courtsManagePhone = '';
          state.formListParams = {
            deptName: val,
            city: state.form.city
          };
          getCourtsManage(state.formListParams);
        }
      }, {
        prop: "courtsManageName",
        label: "台区经理",
        type: "bigData_list_multi",
        placeholder: "请选择台区经理",
        span: 24,
        options: [],
        props: {
          label: 'courtsManageName',
          value: 'courtsManagePhone'
        },
        change: val => {
          state.form.courtsManagePhone = val;
        }
      }, {
        prop: "courtsManagePhone",
        label: "联系电话",
        type: "bigData_list_multi",
        placeholder: "请选择联系电话",
        disabled: true,
        span: 24,
        props: {
          label: 'courtsManagePhone',
          value: 'courtsId'
        },
        options: []
      }, {
        prop: "leaderName",
        label: "领导名称",
        placeholder: "请输入领导名称",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "leaderPhone",
        label: "领导联系电话",
        placeholder: "请输入领导联系电话",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "leaderNickname",
        label: "领导微信昵称",
        placeholder: "请输入领导微信昵称",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "iscAdCode",
        label: "ISC用户账号",
        placeholder: "请输入ISC用户账号",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }],
      // 弹出层标题
      title: "",
      isExport: false,
      isExport: false,
      // 是否显示弹出层
      dialogVisible: false,
      typeUrl: ''
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    onMounted(() => {
      getCityList();
    });
    const handleSelectionChange = function (val) {
      state.currentSelectRows = val;
      if (val.length === 1) {
        state.buttonsList[1].disabled = false;
      } else {
        state.buttonsList[1].disabled = true;
      }
      if (val.length) {
        state.buttonsList[2].disabled = false;
      } else {
        state.buttonsList[2].disabled = true;
      }
    };
    const handleChange = function (val) {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.activeName = val;
      if (val === '一级管理人员') {
        state.tableHeader[1].isShow = false;
        state.tableHeader[2].isShow = false;
        state.tableHeader[8].isShow = true;
        state.tableHeader[10].isShow = false;
      } else {
        state.tableHeader[1].isShow = true;
        state.tableHeader[2].isShow = true;
        state.tableHeader[8].isShow = false;
        state.tableHeader[10].isShow = true;
      }
      resetQuery();
    };
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      if (state.activeName == '二级管理人员') {
        courtsPersonTopoApi.personList(state.queryParams).then(res => {
          if (res.code === 1000) {
            state.list.total = res.data.total || 0;
            state.list.rows = res.data.records || [];
            for (let i in state.list.rows) {
              state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
              let courtsManageName = state.list.rows[i]['courtsManageName'];
              if (courtsManageName != null && courtsManageName != '') {
                state.list.rows[i]['courtsManageName'] = Decrypt(courtsManageName);
              }
              let courtsManagePhone = state.list.rows[i]['courtsManagePhone'];
              if (courtsManagePhone != null && courtsManagePhone != '') {
                state.list.rows[i]['courtsManagePhone'] = Decrypt(courtsManagePhone);
              }
              let courtsManageNameStr = state.list.rows[i]['courtsManageNameStr'];
              if (courtsManageNameStr != null && courtsManageNameStr != '') {
                state.list.rows[i]['courtsManageNameStr'] = Decrypt(courtsManageNameStr);
              }
              let leaderName = state.list.rows[i]['leaderName'];
              if (leaderName != null && leaderName != '') {
                state.list.rows[i]['leaderName'] = Decrypt(leaderName);
              }
              let leaderNickname = state.list.rows[i]['leaderNickname'];
              if (leaderNickname != null && leaderNickname != '') {
                state.list.rows[i]['leaderNickname'] = Decrypt(leaderNickname);
              }
              let leaderPhone = state.list.rows[i]['leaderPhone'];
              if (leaderPhone != null && leaderPhone != '') {
                state.list.rows[i]['leaderPhone'] = Decrypt(leaderPhone);
              }
            }
          }
        }).finally(() => {
          state.loading = false;
        });
      } else {
        courtsPersonTopoApi.personFirstList(state.queryParams).then(res => {
          if (res.code === 1000) {
            state.list.total = res.data.total || 0;
            state.list.rows = res.data.rows || [];
            for (let i in state.list.rows) {
              state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            }
          }
        }).finally(() => {
          state.loading = false;
        });
      }
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.activeName == '二级管理人员') {
        if (state.isExport) {
          ElMessage.closeAll();
          ElMessage.warning('数据正在导出中，请勿重复操作！');
          return;
        }
        state.isExport = true;
        courtsPersonTopoApi.personExport(state.queryParams).then(res => {
          if (res.status === 200) {
            download(res.data, '二级管理人员导出列表.xlsx');
          }
          state.isExport = false;
        }).catch(() => {
          state.isExport = false;
        });
      } else {
        if (state.isExport1) {
          ElMessage.closeAll();
          ElMessage.warning('数据正在导出中，请勿重复操作！');
          return;
        }
        state.isExport1 = true;
        courtsPersonTopoApi.personFirstExport(state.queryParams).then(res => {
          if (res.status === 200) {
            download(res.data, '一级管理人员导出列表.xlsx');
          }
          state.isExport1 = false;
        }).catch(() => {
          state.isExport1 = false;
        });
      }
    };
    // 查询地市下拉
    const getCityList = function () {
      weChatApi.formCity().then(res => {
        if (res.code === 1000) {
          state.formData[0].options = res.data || [];
        }
      });
    };
    // 查询单位下拉
    const getDeptList = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.formData[1].options = res.data || [];
      });
    };
    // 查询台区下拉
    const getCourtsManage = function (params = {}) {
      weChatApi.formCoutrsManage(params).then(res => {
        if (res.data instanceof Array) {
          for (let item of res.data) {
            /**if(item.courtsManageName!=null && item.courtsManageName!=''){
              item.courtsManageName=Decrypt(item.courtsManageName)
            }
            if(item.courtsManagePhone!=null && item.courtsManagePhone!=''){
              item.courtsManagePhone=Decrypt(item.courtsManagePhone)
            }*/
          }
        }
        state.form.courtsManageRows = res.data;
        state.formData[2].options = res.data || [];
      });
    };
    const handleAdd = function () {
      state.operation = '新增';
      state.dialogVisible = true;
      state.title = "新增人员关系";
      nextTick(() => {
        const formRef = unref(formContainer);
        formRef.commonForm.resetFields();
      });
      if (state.activeName == '一级管理人员') {
        state.title = "新增一级管理人员";
        state.formData = [{
          prop: "city",
          label: "所属区县",
          type: "bigData_list_multi",
          placeholder: "请选择所属区县",
          span: 24,
          options: [],
          props: {
            label: 'city',
            value: 'city'
          },
          change: val => {
            state.form.deptName = null;
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              city: val
            };
            getDeptList(state.formListParams);
          }
        }, {
          prop: "deptName",
          label: "所属单位",
          type: "bigData_list_multi",
          placeholder: "请选择所属单位",
          span: 24,
          options: [],
          props: {
            label: 'deptName',
            value: 'deptName'
          },
          change: val => {}
        }, {
          prop: "leaderType",
          label: "人员类型",
          type: "list",
          placeholder: "请选择人员类型",
          span: 24,
          options: [{
            dictLabel: '区县专责',
            dictValue: '区县专责'
          }]
        }, {
          prop: "leaderName",
          label: "领导名称",
          placeholder: "请输入领导名称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderPhone",
          label: "领导联系电话",
          placeholder: "请输入领导联系电话",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderNickname",
          label: "领导微信昵称",
          placeholder: "请输入领导微信昵称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "iscAdCode",
          label: "ISC用户账号",
          placeholder: "请输入ISC用户账号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        state.formData[6].label = "ISC用户账号";
        state.formData[6].placeholder = "请输入ISC用户账号";
      } else {
        state.title = "新增二级管理人员";
        state.formData = [{
          prop: "city",
          label: "所属区县",
          type: "bigData_list_multi",
          placeholder: "请选择所属区县",
          span: 24,
          options: [],
          props: {
            label: 'city',
            value: 'city'
          },
          change: val => {
            state.form.deptName = null;
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              city: val
            };
            getDeptList(state.formListParams);
          }
        }, {
          prop: "deptName",
          label: "所属单位",
          type: "bigData_list_multi",
          placeholder: "请选择所属单位",
          span: 24,
          options: [],
          props: {
            label: 'deptName',
            value: 'deptName'
          },
          change: val => {
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              deptName: val,
              city: state.form.city
            };
            getCourtsManage(state.formListParams);
          }
        }, {
          prop: "courtsManageName",
          label: "台区经理",
          type: "bigData_list_multi",
          placeholder: "请选择台区经理",
          span: 24,
          options: [],
          props: {
            label: 'courtsManageName',
            value: 'courtsManagePhone'
          },
          change: val => {
            state.form.courtsManagePhone = val;
            setIscCode(val);
          }
        }, {
          prop: "courtsManagePhone",
          label: "联系电话",
          type: "bigData_list_multi",
          placeholder: "请选择联系电话",
          disabled: true,
          span: 24,
          props: {
            label: 'courtsManagePhone',
            value: 'courtsId'
          },
          options: []
        }, {
          prop: "courtsIscId",
          label: "台区经理ISC账号",
          placeholder: "请输入台区经理ISC账号",
          type: "text",
          disabledHandle() {
            return true;
          },
          span: 24
        }, {
          prop: "leaderName",
          label: "领导名称",
          placeholder: "请输入领导名称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderPhone",
          label: "领导联系电话",
          placeholder: "请输入领导联系电话",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderNickname",
          label: "领导微信昵称",
          placeholder: "请输入领导微信昵称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "iscAdCode",
          label: "ISC用户账号",
          placeholder: "请输入ISC用户账号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        state.formData[8].label = "班所长ISC用户账号";
        state.formData[8].placeholder = "请输入班所长ISC用户账号";
      }
      getCityList();
      state.form = {
        city: null,
        deptName: null,
        courtsManageName: null,
        leaderName: null,
        leaderPhone: null,
        leaderNickname: null,
        leaderType: null,
        courtsIscId: null
      };
    };
    const handleEdit = function () {
      if (state.currentSelectRows.length > 1) {
        ElMessage.closeAll();
        ElMessage.warning('请选择一条数据进行编辑操作！');
        return;
      }
      state.operation = '编辑';
      if (state.activeName == '一级管理人员') {
        state.title = "编辑一级管理人员";
        state.formData = [{
          prop: "city",
          label: "所属区县",
          type: "bigData_list_multi",
          placeholder: "请选择所属区县",
          span: 24,
          options: [],
          props: {
            label: 'city',
            value: 'city'
          },
          change: val => {
            state.form.deptName = null;
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              city: val
            };
            getDeptList(state.formListParams);
          }
        }, {
          prop: "deptName",
          label: "所属单位",
          type: "bigData_list_multi",
          placeholder: "请选择所属单位",
          span: 24,
          options: [],
          props: {
            label: 'deptName',
            value: 'deptName'
          },
          change: val => {}
        }, {
          prop: "leaderType",
          label: "人员类型",
          type: "list",
          placeholder: "请选择人员类型",
          span: 24,
          options: [{
            dictLabel: '区县专责',
            dictValue: '区县专责'
          }]
        }, {
          prop: "leaderName",
          label: "领导名称",
          placeholder: "请输入领导名称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderPhone",
          label: "领导联系电话",
          placeholder: "请输入领导联系电话",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderNickname",
          label: "领导微信昵称",
          placeholder: "请输入领导微信昵称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "iscAdCode",
          label: "ISC用户账号",
          placeholder: "请输入ISC用户账号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        state.formData[6].label = "ISC用户账号";
        state.formData[6].placeholder = "请输入ISC用户账号";
      } else {
        state.title = "编辑二级管理人员";
        state.formData = [{
          prop: "city",
          label: "所属区县",
          type: "bigData_list_multi",
          placeholder: "请选择所属区县",
          disabled: true,
          span: 24,
          options: [],
          props: {
            label: 'city',
            value: 'city'
          },
          change: val => {
            state.form.deptName = null;
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              city: val
            };
            getDeptList(state.formListParams);
          }
        }, {
          prop: "deptName",
          label: "所属单位",
          type: "bigData_list_multi",
          placeholder: "请选择所属单位",
          disabled: true,
          span: 24,
          options: [],
          props: {
            label: 'deptName',
            value: 'deptName'
          },
          change: val => {
            state.form.courtsManageName = null;
            state.form.courtsManagePhone = '';
            state.formListParams = {
              deptName: val,
              city: state.form.city
            };
            getCourtsManage(state.formListParams);
          }
        }, {
          prop: "courtsManageName",
          label: "台区经理",
          type: "bigData_list_multi",
          placeholder: "请选择台区经理",
          disabled: true,
          span: 24,
          options: [],
          props: {
            label: 'courtsManageName',
            value: 'courtsManagePhone'
          },
          change: val => {
            state.form.courtsManagePhone = val;
            setIscCode(val);
          }
        }, {
          prop: "courtsManagePhone",
          label: "联系电话",
          type: "bigData_list_multi",
          placeholder: "请选择联系电话",
          disabled: true,
          span: 24,
          props: {
            label: 'courtsManagePhone',
            value: 'courtsId'
          },
          options: []
        }, {
          prop: "courtsIscId",
          label: "台区经理ISC账号",
          placeholder: "请输入台区经理ISC账号",
          type: "text",
          disabled: true,
          isSelect: false,
          span: 24,
          disabledHandle() {
            return true;
          },
          minWidth: '328px'
        }, {
          prop: "leaderName",
          label: "领导名称",
          placeholder: "请输入领导名称",
          type: "text",
          isSelect: false,
          span: 24,
          disabledHandle() {
            return true;
          },
          minWidth: '328px'
        }, {
          prop: "leaderPhone",
          label: "领导联系电话",
          placeholder: "请输入领导联系电话",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "leaderNickname",
          label: "领导微信昵称",
          placeholder: "请输入领导微信昵称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "iscAdCode",
          label: "ISC用户账号",
          placeholder: "请输入ISC用户账号",
          type: "text",
          isSelect: false,
          span: 24,
          disabledHandle() {
            return true;
          },
          minWidth: '328px'
        }];
        state.formData[8].label = "班所长ISC用户账号";
        state.formData[8].placeholder = "请输入班所长ISC用户账号";
      }
      getCityList();
      // 组装参数 重新拉取下拉框选项值
      getDeptList({
        city: state.form.city
      });
      getCourtsManage({
        city: state.form.city,
        deptName: state.form.deptName
      });
      state.form = JSON.parse(JSON.stringify(state.currentSelectRows[0]));
      state.dialogVisible = true;
      state.form.courtsIscId = state.currentSelectRows[0].iscAdCode;
    };
    const setIscCode = function (val) {
      state.form.courtsManageRows.forEach((item, index) => {
        if (item.courtsManagePhone == val) {
          state.form.courtsIscId = item.cISCAdCode;
          state.form.courtsManageNameStr = item.courtsManageName;
        }
      });
    };
    const handleDel = function () {
      if (state.activeName == '二级管理人员') {
        const arrayRows = [];
        for (let item of state.currentSelectRows) {
          const iscRows = {
            discadCode: item.discadCode,
            iscAdCode: item.iscAdCode
          };
          arrayRows.push(iscRows);
        }
        courtsPersonTopoApi.personDel(arrayRows).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('删除成功');
            handleQuery();
          }
        });
      } else {
        const ids = [];
        for (let item of state.currentSelectRows) {
          ids.push(item.id);
        }
        courtsPersonTopoApi.personFirstDel(ids).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('删除成功');
            handleQuery();
          }
        });
      }
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      handleQuery();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          if (state.activeName == '二级管理人员') {
            if (state.form.courtsManageName != null && state.form.courtsManageName != '') {
              state.form.courtsManageName = Encrypt(state.form.courtsManageName);
            }
            if (state.form.courtsManagePhone != null && state.form.courtsManagePhone != '') {
              state.form.courtsManagePhone = Encrypt(state.form.courtsManagePhone);
            }
            if (state.form.leaderName != null && state.form.leaderName != '') {
              state.form.leaderName = Encrypt(state.form.leaderName);
            }
            if (state.form.leaderNickname != null && state.form.leaderNickname != '') {
              state.form.leaderNickname = Encrypt(state.form.leaderNickname);
            }
            if (state.form.leaderPhone != null && state.form.leaderPhone != '') {
              state.form.leaderPhone = Encrypt(state.form.leaderPhone);
            }
            delete state.form.courtsManageRows;
            if (state.operation === '新增') {
              courtsPersonTopoApi.personAdd(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('新增成功');
                  clearForm();
                  handleQuery();
                }
              });
            } else {
              courtsPersonTopoApi.personEdit(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('编辑成功');
                  clearForm();
                  handleQuery();
                }
              });
            }
          } else {
            if (state.operation === '新增') {
              courtsPersonTopoApi.personFirstAdd(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('新增成功');
                  clearForm();
                  handleQuery();
                }
              });
            } else {
              courtsPersonTopoApi.personFirstEdit(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('编辑成功');
                  clearForm();
                  handleQuery();
                }
              });
            }
          }
        }
      });
    };
    const clearForm = function () {
      state.dialogVisible = false;
      state.operation = '新增';
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      handleSelectionChange,
      handleChange,
      getList,
      downloadExport,
      getCityList,
      getDeptList,
      getCourtsManage,
      handleAdd,
      handleEdit,
      setIscCode,
      handleDel,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get weChatApi() {
        return weChatApi;
      },
      get courtsPersonTopoApi() {
        return courtsPersonTopoApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      },
      get Encrypt() {
        return Encrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};