import { weChatrequest } from '@/utils/request';
export default {
  name: 'textRobotPage',
  props: {},
  data() {
    /*return {
        iframeUrl: '',
        isVisible:false
      };*/
  },
  methods: {
    refresh() {
      //this.$router.go(0)
    }
  },
  watch: {
    //检测路由参数发生改变时，刷新当前页面 调用
    '$route': function (url) {
      if (url.fullPath == '/textRobot') {
        authenticationInfo().then(res => {
          if (res.code === 1000 && null != res.data.requestUrl && '' != res.data.requestUrl) {
            openNewTab(res.data.requestUrl);
          }
        });
      }
    }
  },
  created() {},
  mounted() {
    authenticationInfo().then(res => {
      if (res.code === 1000 && null != res.data.requestUrl && '' != res.data.requestUrl) {
        //this.isVisible=true
        //this.iframeUrl=res.data.requestUrl
        openNewTab(res.data.requestUrl);
      }
    });
  }
};
export function openNewTab(requestUrl) {
  // 获取屏幕宽度和高度
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  // 新窗口的特征，如宽度和高度
  const windowFeatures = 'width=1024,height=768';
  // 打开新窗口
  const newWindow = window.open(requestUrl, '_blank', windowFeatures);
  //设置窗口大小并居中
  if (newWindow) {
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(screen.width, screen.height);
  }
}
//单点登录
export function authenticationInfo() {
  return weChatrequest({
    url: '/isc/getAuthenticationInfo',
    method: 'post'
  });
}