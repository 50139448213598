import {weChatrequest} from '@/utils/request'

// 微信群列表查询
export function weChatList(data) {
    return weChatrequest({
        url: '/wechatGroup/findPage',
        method: 'post',
        data
    })
}

// 微信群列表导出
export function weChatExport(data) {
    return weChatrequest({
        url: '/wechatGroup/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 微信群新增
export function weChatAdd(data) {
    return weChatrequest({
        url: '/wechatGroup/create',
        method: 'post',
        data
    })
}

// 微信群编辑
export function weChatEdit(data) {
    return weChatrequest({
        url: '/wechatGroup/update',
        method: 'post',
        data
    })
}

// 微信群删除
export function weChatDel(data) {
    return weChatrequest({
        url: '/wechatGroup/deleteByids',
        method: 'post',
        data
    })
}
export function formSecondDept(data) {
    return weChatrequest({
        url: '/courts/getSecondDept',
        method: 'post',
        data
    })
}
// 新增/编辑所属地市下拉
export function formCity(data) {
    return weChatrequest({
        url: '/courts/getCourtsCity',
        method: 'post',
        data
    })
}

// 新增/编辑所属单位下拉
export function formDept(data) {
    return weChatrequest({
        url: '/courts/getCourtsDept',
        method: 'post',
        data
    })
}
export function formLines(data) {
    return weChatrequest({
        url: '/courts/getCourtsLineName',
        method: 'post',
        data
    })
}
export function formFourth(data) {
    return weChatrequest({
        url: '/courts/getCourtsFourth',
        method: 'post',
        data
    })
}
// 新增/编辑所属单位下拉
export function formDeptMuti(data) {
    return weChatrequest({
        url: '/courts/getCourtsDeptMuti',
        method: 'post',
        data
    })
}

// 新增/编辑所属台区下拉
export function formCourts(data) {
    return weChatrequest({
        url: '/courts/getCourtsName',
        method: 'post',
        data
    })
}

// 新增/编辑所属台区下拉
export function formCourtsMuti(data) {
    return weChatrequest({
        url: '/courts/getCourtsNameMuti',
        method: 'post',
        data
    })
}
export function formCourtsManageMuti(data) {
    return weChatrequest({
        url: '/courts/getCourtsManageNameMuti',
        method: 'post',
        data
    })
}

// 新增/编辑所属台区经理下拉
export function formCoutrsManage(data) {
    return weChatrequest({
        url: '/courts/getCourtsManageName',
        method: 'post',
        data
    })
}