import * as echarts from "echarts";
import { defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {},
  name: '',
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "100%"
    },
    pieData: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: '总数'
    },
    top: {
      type: String,
      default: '34%'
    },
    left: {
      type: String,
      default: '28%'
    }
  },
  setup(props) {
    const store = useStore();
    let myEcharts = ref(null);
    let myChart = null; //要用普通变量来接收 echarts 实例,否则echarts图 tooltip 不显示
    const state = reactive({
      option: null,
      tooltioBackgroundColor: "#0A4C5D",
      titleColor: "#fff"
    });
    const setOptions = val => {
      state.option = {
        title: {
          text: props.title,
          subtext: val.allCount + '个',
          subtextStyle: {
            fontSize: fontSizeChange(0.16),
            color: state.titleColor
          },
          textAlign: 'center',
          left: props.left,
          top: props.top,
          textStyle: {
            fontSize: fontSizeChange(0.16),
            color: state.titleColor
          }
        },
        color: ["#00C1FF", "#FBD462", "#FF8A42", "#0BC4BE", "#FF4A55"],
        tooltip: {
          show: true,
          trigger: "item",
          backgroundColor: state.tooltioBackgroundColor,
          borderColor: state.tooltioBackgroundColor,
          textStyle: {
            color: "#fff"
          },
          formatter: '{b}:  {d}%  ( {c}个 )'
        },
        legend: {
          type: "scroll",
          icon: 'circle',
          bottom: "2%",
          itemGap: fontSizeChange(0.16),
          formatter: function (name) {
            return name;
          },
          textStyle: {
            rich: {
              title: {
                fontSize: fontSizeChange(0.14),
                color: state.titleColor
              }
            }
          }
        },
        series: [{
          type: "pie",
          radius: ["35%", "55%"],
          avoidLabelOverlap: true,
          label: {
            show: false
          },
          label: {
            normal: {
              formatter: '{b}:{c}个: ({d}%)',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14
              }
            }
          },
          data: val.series
        }]
      };
      myChart.setOption(state.option);
    };
    // 字体自适应。
    const fontSizeChange = val => {
      let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return val * fontSize;
    };
    onMounted(() => {
      state.tooltioBackgroundColor = "rgba(50,50,50,0.7)";
      state.titleColor = "#000";
      nextTick(() => {
        myChart = echarts.init(myEcharts.value);
        setOptions(props.pieData);
      });
    });
    onBeforeUnmount(() => {
      if (myChart) {
        myChart.dispose(); //销毁
      }
    });
    //数据监听
    watch(() => props.pieData, () => {
      if (myChart) {
        myChart.clear();
      }
      setOptions(props.pieData);
    }, {
      deep: true
    });
    //窗口监听渲染
    window.addEventListener('resize', () => {
      setOptions(props.pieData);
      myChart.resize();
    });
    return {
      ...toRefs(state),
      myEcharts
    };
  }
});