import ScrollPane from "./ScrollPane";
import { setCurrentTab } from "@/utils/storage";
import { linkActiveHandle } from "@/utils/index";
import path from "path";
export default {
  name: "TagsView",
  components: {
    ScrollPane
  },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: []
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.common.visitedViews;
    },
    routes() {
      return this.$store.state.common.routes;
    }
  },
  watch: {
    $route() {
      this.addTags();
      //  this.moveToCurrentTag();
    },

    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    }
  },
  mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    clickHandle(tag) {
      // setCurrentTab(tag.currentTab);
      // if (!this.$route.path.startsWith(tag.currentTab)) {
      //   this.$store.commit("menu/setLink", true);
      //   const active = linkActiveHandle(
      //     Object.assign(tag, {
      //       meta: {
      //         link: tag.path,
      //       },
      //     })
      //   );
      // console.log("active=======", active);

      // this.$store.dispatch("menu/setActive", active);
      // }
      // console.log("tag=========", tag);
      // setTimeout(() => {
      //   console.log(this.$store.state.menu.active);
      // }, 1000);
      //是外链

      console.log("tag=========", tag);
      console.log("this.$route.path", this.$route.path);
      if (!tag.path.startsWith(tag.currentTab)) {
        this.$router.push(tag.fullPath);
      } else {
        this.$router.push({
          path: tag.path,
          query: tag.query
        });
      }
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    filterAffixTags(routes, basePath = "/") {
      let tags = [];
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.join(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: {
              ...route.meta
            }
          });
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags];
          }
        }
      });
      return tags;
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes);
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch("common/addVisitedView", tag);
        }
      }
    },
    addTags() {
      const {
        name
      } = this.$route;
      // console.log("route----", this.$route);
      // let on =    !this.$route.path.startsWith('/module/AI')

      if (name) {
        this.$store.dispatch("common/addView", this.$route);
        this.$forceUpdate();
      }
      return false;
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            console.log(this.$refs.scrollPane, "======this.$refs");
            this.$refs.scrollPane.moveToCurrentTag(tag);
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch("common/updateVisitedView", this.$route);
            }
            break;
          }
        }
      });
    },
    refreshSelectedTag(view) {
      this.$store.dispatch("common/delCachedView", view).then(() => {
        const {
          fullPath
        } = view;
        this.$nextTick(() => {
          this.$router.replace({
            path: "/redirect" + fullPath
          });
        });
      });
    },
    closeSelectedTag(view) {
      this.$store.dispatch("common/delView", view).then(({
        visitedViews
      }) => {
        this.$forceUpdate();
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag);
      this.$store.dispatch("common/delOthersViews", this.selectedTag).then(() => {
        this.moveToCurrentTag();
      });
    },
    closeAllTags(view) {
      this.$store.dispatch("common/delAllViews").then(({
        visitedViews
      }) => {
        if (this.affixTags.some(tag => tag.path === view.path)) {
          return;
        }
        this.toLastView(visitedViews, view);
      });
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      console.log('last-view', latestView, view);
      if (latestView) {
        this.$router.push(latestView);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "home") {
          // to reload home page
          this.$router.replace({
            path: "/redirect" + view.fullPath
          });
        } else {
          this.$router.push("/");
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      //减去顶部导航的高度
      this.top = e.clientY - 78;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    }
  }
};