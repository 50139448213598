import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fac8ca64"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "tags-view-container",
  class: "tags-view-container"
};
const _hoisted_2 = ["onClick", "onMouseup", "onContextmenu"];
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_scroll_pane = _resolveComponent("scroll-pane");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_scroll_pane, {
    ref: "scrollPane",
    class: "tags-view-wrapper"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.visitedViews, tag => {
      return _openBlock(), _createElementBlock("span", {
        ref_for: true,
        ref: "tag",
        key: tag.path,
        class: _normalizeClass([$options.isActive(tag) ? 'active' : '', "tags-view-item"]),
        tag: "span",
        onClick: $event => $options.clickHandle(tag),
        onMouseup: _withModifiers($event => $options.closeSelectedTag(tag), ["middle"]),
        onContextmenu: _withModifiers($event => $options.openMenu(tag, $event), ["prevent"])
      }, [_createTextVNode(_toDisplayString(tag.title) + " ", 1 /* TEXT */), !tag.meta.affix ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "iconfont icon-guanbi close-btn",
        onClick: _withModifiers($event => $options.closeSelectedTag(tag), ["prevent", "stop"])
      }, null, 8 /* PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true)], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), _withDirectives(_createElementVNode("ul", {
    style: _normalizeStyle({
      left: $data.left + 'px',
      top: $data.top + 'px'
    }),
    class: "contextmenu"
  }, [_createElementVNode("li", {
    onClick: _cache[0] || (_cache[0] = $event => $options.refreshSelectedTag($data.selectedTag))
  }, "刷新页面"), !($data.selectedTag.meta && $data.selectedTag.meta.affix) ? (_openBlock(), _createElementBlock("li", {
    key: 0,
    onClick: _cache[1] || (_cache[1] = $event => $options.closeSelectedTag($data.selectedTag))
  }, " 关闭当前 ")) : _createCommentVNode("v-if", true), _createElementVNode("li", {
    onClick: _cache[2] || (_cache[2] = (...args) => $options.closeOthersTags && $options.closeOthersTags(...args))
  }, "关闭其他"), _createElementVNode("li", {
    onClick: _cache[3] || (_cache[3] = $event => $options.closeAllTags($data.selectedTag))
  }, "关闭所有")], 4 /* STYLE */), [[_vShow, $data.visible]])]);
}