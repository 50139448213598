// commonRegister.js
import Cookies from "js-cookie";
import { login, logout, getRouters ,getRole,justAdmin,getDeptInfo,getISCCode} from "../api/login";
import { getToken, setToken, removeToken } from "../utils/auth";
import { getServerTime } from "../utils/setHeader";
import axios from 'axios'
import { constantRoutes } from "../router";
import { getCurrentTab } from "@/utils/storage";
//settings
import defaultSettings from "../utils/settings";
const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings;
import { getRedictUrl,handToken } from "@/utils/auth";
//settings---end

/**
 *
 * @param {vuex实例} store
 * @param {qiankun下发的props} props
 * @param {vue-router实例} router
 * @param {Function} resetRouter - 重置路由方法
 */
function registerCommonModule(
  store,
  props = {},
  router,
  resetRouter
) {
  // console.log('props', props)
  // console.log('store-***-', store)
  if (!store) {
    return;
  }
  // 深拷贝一份基础路由
  // 获取初始化的state
  // eslint-disable-next-line no-mixed-operators
  const initState = (props.store && props.store.state.common) || {
    token: getToken(),
    tokenStatus: true, //token 状态
    name: "",
    avatar: "",
    roles: [],
    visitedViews: [],
    userInfo: "",
    deptInfo:"",
    cachedViews: [],
    permissions: ["*:*:*"],
    dept: null,
    routes: [],
    addRoutes: [],
    menu: [],
    adminleftnavnum: "1", //左侧导航选中的菜单
    showSettings: showSettings,
    tagsView: tagsView,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo,
    sidebar: {
      opened: Cookies.get("sidebarStatus")
        ? !!+Cookies.get("sidebarStatus")
        : true,
      withoutAnimation: false,
    },
    device: "desktop",
    size: Cookies.get("size") || "medium",
    serverTime: 0,
    serverTimeInterval: null,
    app: "main", // 启用应用名，默认main(主应用)，区分各个应用下，如果运行的是pms，则是pms，用于判断路由
  };

  // 将父应用的数据存储到子应用中，命名空间固定为common

  const commonModule = {
    namespaced: true,
    state: initState,
    actions: {
      // 登录
      Login({ commit, dispatch }, userInfo) {
        const username = userInfo.username.trim();
        const password = userInfo.password;
        const code = userInfo.code;
        const uuid = userInfo.uuid;
        return new Promise((resolve, reject) => {
          login(username, password, code, uuid)
            .then((res) => {
              setToken(res.token);
              localStorage.setItem("userId", uuid);
              commit("SET_TOKEN", res.token);
              // dispatch('GetInfo')  子应用在permission 中触发

              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      refreshToken({ commit }, token) {
        commit("SET_TOKEN", token);
        setToken(token);
      },

      // 获取用户信息
      GetInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: '/isc_sso/oauth2.0/profile?access_token='+getToken(),
            timeout: 5000 * 60 // 单独设置超时时间
          }).then((res) => {
            if (res) {
              console.log('用户信息----', res)
              sessionStorage.setItem('currentName',res.data.name);
              sessionStorage.setItem('currentISCCode',res.data.iscAdCode);
              commit("setUserInfo", res);
              resolve(res);
            }
          })
          .catch((error) => {
            reject(error);
          });
        });
      },
      // 获取组织机构信息
      GetDeptInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
          getDeptInfo({
            systemId:'ff8080818deb9978018f990659f62d24',
            userId:state.userInfo.data.iscUserId
          }).then(res=>{
            if(res.code===1000){
              console.log('组织机构信息----', res)
              if(res?.data?.length>0){
                sessionStorage.setItem('currentDept',res.data[0].name);
                commit("setDeptInfo", res.data[0]);
              }
              resolve(res.data)
            }
          }).catch((error) => {
            reject(error);
          });
        });
      },
      // 退出系统
      LogOut({ commit, state }) {
        //console.log(getRedictUrl,'9999999999999999999999999999')
        return new Promise((resolve, reject) => {
          //logout(state.token)
          handToken(2)
          axios({
            method: 'post',
            url: '/isc_sso/logout',
            data:{
                iscUserId:state.userInfo.data.iscUserId,
                redirect_uri:getRedictUrl()
            }
          })
            .then(() => {
              commit("SET_TOKEN", "");
              commit("SET_NAME", "");
              commit("SET_DEPT", "");
              commit("SET_ROLES", []);
              commit("SET_PERMISSIONS", []);
              removeToken();
              commit("updateTokenStatus", false);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      // getMenu({ commit }) {
      //   return new Promise((resolve) => {
      //   //   // 向后端请求路由数据
      //   //   getRouters().then((res) => {
      //   //     let router = res.data.router;
      //       // let copyRoute = JSON.parse(JSON.stringify(router));
      //       commit("SET_ROUTES",[{
      //         path:'/robotManage',
      //         name:'robotManage',
      //         meta:{ title: '机器人管理', icon: '' },
      //         component:'robotManage'
      //       }]);
      //   //     if (router) {
      //   //       resolve(copyRoute);
      //   //     } else {
      //         resolve([]);
      //   //     }
      //   //   });
      //   });
      // },
      getMenu({ commit ,state}) {
              return new Promise((resolve) => {
      //          getRole({
      //            systemId:'ff8080818caab210018cf0dffcfa2718',
      //            userId:state.userInfo.data.iscUserId
      //          }).then(res=>{
      //            if(res.code===1000){
                    // 若不存在roleList则代表管理员，管理员给与所有权限；其他角色根据角色获取菜单权限
                    if(false){
      //                sessionStorage.setItem('isAdmin',false);
      //                getRouters(res.data.roleList[0].id).then(response=>{
      //                  if(response.code===1000){
      //                    if(response.data.funcNode?.length){
      //                      const _temp=[]
      //                      for(let item of response.data.funcNode){
      //                        _temp.push({
      //                          path: item.currentNode.busiCode,
      //                          name:item.currentNode.busiCode,
      //                          meta: { title: item.currentNode.name, icon: '' },
      //                          component: item.currentNode.busiCode,
      //                        })
      //                      }
      //                      commit("SET_ROUTES",_temp);
      //                      resolve(_temp)
      //                    }
      //                  }else{
      //                    resolve([])
      //                  }
      //                })
                    }else{
                      sessionStorage.setItem('isAdmin',true);

                      sessionStorage.setItem('roleInfo','wgwxjzbl');
                      sessionStorage.setItem('orgRoleInfo','123456');
                      sessionStorage.setItem('currentName','曹明然');
                      sessionStorage.setItem('currentISCCode','t123610');
                      sessionStorage.setItem('currentDept','国网四平供电公司');

                      // sessionStorage.setItem('roleInfo', 'wgwxfwzz');
                      // sessionStorage.setItem('currentName', '王元乾');
                      // sessionStorage.setItem('currentISCCode', 't18544349967');
                      // sessionStorage.setItem('currentDept', '铁东区供电中心');

                      const _routes=[{
                        path: "courtsManage",
                        name:'courtsManage',
                        meta: { title: '台区管理', icon: '' },
                        component: "courtsManage",
                      },
                      {
                        path: "courtsPersonTopo",
                        name:'courtsPersonTopo',
                        meta: { title: '台区人员拓扑', icon: '' },
                        component: "courtsPersonTopo",
                      },
                      {
                        path: "robotManage",
                        name:'robotManage',
                        meta: { title: '机器人管理', icon: '' },
                        component: "robotManage",
                      },
                      {
                        path: "weChatGroupManage",
                        name:'weChatGroupManage',
                        meta: { title: '微信群管理', icon: '' },
                        component: 'weChatGroupManage',
                      },
                      {
                        path: "weChatStatistics",
                        name:'weChatStatistics',
                        meta: { title: '微信群运行情况统计', icon: '' },
                        component: "weChatStatistics",
                      },
                      {
                        path: "appealManage",
                        name:'appealManage',
                        meta: { title: '诉求管理', icon: '' },
                        component: "appealManage",
                      },
                      {
                        path: "serverInfoList",
                        name:'serverInfoList',
                        meta: { title: '服务信息列表', icon: '' },
                        component: "serverInfoList",
                      },
                       {
                         path: "textRobot",
                         name:'textRobot',
                         meta: { title: '文本机器人', icon: '' },
                         component: "textRobot",
                       },
                       {
                         path: "setKeyWord",
                         name:'setKeyWord',
                         meta: { title: '词条维护', icon: '' },
                         component: "setKeyWord",
                       },
                      {
                        path: "sentimentMonitor",
                        name:'sentimentMonitor',
                        meta: { title: '舆情监控', icon: '' },
                        component: "sentimentMonitor",
                      },
                       {
                         path: "weChatGroupCoverCourt",
                         name:'weChatGroupCoverCourt',
                         meta: { title: '微信群覆盖台区', icon: '' },
                         component: "weChatGroupCoverCourt",
                       }
                      ]
                      commit("SET_ROUTES",_routes);
                      resolve(_routes)
                    }
      //            } else{
      //              resolve([])
      //            }
      //          })
              });
            },
      // 前端 登出
      FedLogOut({ commit }) {
        return new Promise((resolve) => {
          commit("SET_TOKEN", "");
          removeToken();
          resolve();
        });
      },
      setApp({ commit, dispatch }, appName) {
        commit("setApp", appName);
      },

      // app
      changeSetting({ commit }, data) {
        commit("CHANGE_SETTING", data);
      },
      toggleSideBar({ commit }) {
        commit("TOGGLE_SIDEBAR");
      },
      closeSideBar({ commit }, { withoutAnimation }) {
        commit("CLOSE_SIDEBAR", withoutAnimation);
      },
      toggleDevice({ commit }, device) {
        commit("TOGGLE_DEVICE", device);
      },
      setSize({ commit }, size) {
        commit("SET_SIZE", size);
      },
      //app end

      //标签 tags 缓存
      addView({ dispatch, commit }, view) {
        dispatch("addVisitedView", view);
        dispatch("addCachedView", view);
      },
      addVisitedView({ commit }, view) {
        commit("ADD_VISITED_VIEW", view);
        // commit('emitGlobalState')
      },
      addCachedView({ commit }, view) {
        commit("ADD_CACHED_VIEW", view);
      },

      delView({ dispatch, state }, view) {
        return new Promise((resolve) => {
          dispatch("delVisitedView", view);
          dispatch("delCachedView", view);
          resolve({
            visitedViews: [...state.visitedViews],
            cachedViews: [...state.cachedViews],
          });
        });
      },
      delVisitedView({ commit, state }, view) {
        return new Promise((resolve) => {
          commit("DEL_VISITED_VIEW", view);
          resolve([...state.visitedViews]);
        });
      },
      delCachedView({ commit, state }, view) {
        return new Promise((resolve) => {
          commit("DEL_CACHED_VIEW", view);
          resolve([...state.cachedViews]);
        });
      },

      delOthersViews({ dispatch, state }, view) {
        return new Promise((resolve) => {
          dispatch("delOthersVisitedViews", view);
          dispatch("delOthersCachedViews", view);
          resolve({
            visitedViews: [...state.visitedViews],
            cachedViews: [...state.cachedViews],
          });
        });
      },
      delOthersVisitedViews({ commit, state }, view) {
        return new Promise((resolve) => {
          commit("DEL_OTHERS_VISITED_VIEWS", view);
          resolve([...state.visitedViews]);
        });
      },
      delOthersCachedViews({ commit, state }, view) {
        return new Promise((resolve) => {
          commit("DEL_OTHERS_CACHED_VIEWS", view);
          resolve([...state.cachedViews]);
        });
      },

      delAllViews({ dispatch, state }, view) {
        return new Promise((resolve) => {
          dispatch("delAllVisitedViews", view);
          dispatch("delAllCachedViews", view);
          resolve({
            visitedViews: [...state.visitedViews],
            cachedViews: [...state.cachedViews],
          });
        });
      },
      delAllVisitedViews({ commit, state }) {
        return new Promise((resolve) => {
          commit("DEL_ALL_VISITED_VIEWS");
          resolve([...state.visitedViews]);
        });
      },
      delAllCachedViews({ commit, state }) {
        return new Promise((resolve) => {
          commit("DEL_ALL_CACHED_VIEWS");
          resolve([...state.cachedViews]);
        });
      },

      updateVisitedView({ commit }, view) {
        commit("UPDATE_VISITED_VIEW", view);
      },
      getServerTime({ commit, state }) {
        return new Promise((resolve) => {
          getServerTime().then((res) => {
            if (res.code === 1000) {
              commit("UPDATE_SERVER_TIME", res.data);
              if (state.serverTimeInterval) {
                clearInterval(state.serverTimeInterval);
                state.serverTimeInterval = null;
              }
              state.serverTimeInterval = setInterval(() => {
                commit("UPDATE_SERVER_TIME");
              }, 1000);
              document.addEventListener("visibilitychange", function () {
                if (document.visibilityState === "hidden") {
                  if (state.serverTimeInterval) {
                    clearInterval(state.serverTimeInterval);
                    state.serverTimeInterval = null;
                  }
                } else {
                  getServerTime().then((res) => {
                    commit("UPDATE_SERVER_TIME", res.data);
                    if (state.serverTimeInterval) {
                      clearInterval(state.serverTimeInterval);
                      state.serverTimeInterval = null;
                    }
                    state.serverTimeInterval = setInterval(() => {
                      commit("UPDATE_SERVER_TIME");
                    }, 1000);
                  });
                }
              });
              resolve();
            }
          });
        });
      },
      //标签 end
    },
    mutations: {
      UPDATE_SERVER_TIME: (state, payload) => {
        if (payload) {
          state.serverTime = payload;
        } else {
          state.serverTime += 1;
        }
      },

      updateTokenStatus(state, bool) {
        state.tokenStatus = bool;
      },

      SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions;
      },
      SET_ROLES: (state, roles) => {
        state.roles = roles;
      },
      SET_ROUTES(state, payload) {
        const routesChange=constantRoutes.find(item=>item.name==='layoutRoute')
        routesChange.children=routesChange.children.concat(payload)
        state.routes = constantRoutes
        state.menu = constantRoutes
      },
      SET_MENU(state, payload) {
        state.menu = payload;
      },

      setAuth(state, data) {
        state.auth = data || {};
        if (data) {
          setToken(data);
        } else {
          removeToken();
        }
      },
      setUserInfo(state, data) {
        state.userInfo = data;
      },
      setDeptInfo(state, data) {
        state.deptInfo = data;
      },
      setApp(state, appName) {
        state.common = appName;
      },
      SET_TOKEN: (state, token) => {
        state.token = token;
      },
      SET_NAME: (state, name) => {
        state.name = name;
        window.localStorage.setItem("name", name);
      },
      SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
      },

      SET_DEPT: (state, dept) => {
        state.dept = dept;
        window.localStorage.setItem("deptName", dept.deptName);
      },

      //
      CHANGE_SETTING: (state, { key, value }) => {
        if (state.hasOwnProperty(key)) {
          state[key] = value;
        }
      },
      TOGGLE_SIDEBAR: (state) => {
        state.sidebar.opened = !state.sidebar.opened;
        state.sidebar.withoutAnimation = false;
        if (state.sidebar.opened) {
          Cookies.set("sidebarStatus", 1);
        } else {
          Cookies.set("sidebarStatus", 0);
        }
      },
      CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set("sidebarStatus", 0);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
      },
      TOGGLE_DEVICE: (state, device) => {
        state.device = device;
      },
      SET_SIZE: (state, size) => {
        state.size = size;
        Cookies.set("size", size);
      },
      //tags 标签缓存

      ADD_VISITED_VIEW: (state, view) => {
        console.log(view, "view=========");
        let hasView = state.visitedViews.find((v) => v.path === view.path);
        if (hasView) {
          // hasView.currentTab = getCurrentTab()
          return;
        }
        state.visitedViews.push(
          Object.assign(
            true,
            { currentTab: getCurrentTab() },
            { ...view },
            {
              title: view.meta.title || "no-name",
            }
          )
        );
      },

      ADD_CACHED_VIEW: (state, view) => {
        if (state.cachedViews.includes(view.name)) return;
        //三级路由
        if (view.matched.length === 3) {
          if (!state.cachedViews.includes(view.matched[1].name)) {
            state.cachedViews.push(view.matched[1].name);
          }
        }
        if (!view.meta.noCache) {
          state.cachedViews.push(view.name);
        }
      },

      DEL_VISITED_VIEW: (state, view) => {
        let visited = state.visitedViews;
        for (const [i, v] of state.visitedViews.entries()) {
          if (v.path === view.path) {
            visited.splice(i, 1);
            state.visitedViews = visited;

            break;
          }
        }
      },
      DEL_CACHED_VIEW: (state, view) => {
        console.log(view, "view----------");
        for (const i of state.cachedViews) {
          if (i === view.name) {
            const index = state.cachedViews.indexOf(i);
            state.cachedViews.splice(index, 1);
            break;
          }
        }
      },

      DEL_OTHERS_VISITED_VIEWS: (state, view) => {
        state.visitedViews = state.visitedViews.filter((v) => {
          return v.meta.affix || v.path === view.path;
        });
      },
      DEL_OTHERS_CACHED_VIEWS: (state, view) => {
        for (const i of state.cachedViews) {
          if (i === view.name) {
            const index = state.cachedViews.indexOf(i);
            state.cachedViews = state.cachedViews.slice(index, index + 1);
            break;
          }
        }
      },

      DEL_ALL_VISITED_VIEWS: (state) => {
        const affixTags = state.visitedViews.filter((tag) => tag.meta.affix);
        state.visitedViews = affixTags;
      },
      DEL_ALL_CACHED_VIEWS: (state) => {
        state.cachedViews = [];
      },

      UPDATE_VISITED_VIEW: (state, view) => {
        for (let v of state.visitedViews) {
          if (v.path === view.path) {
            v = Object.assign(v, view);
            break;
          }
        }
      },
      //设置换肤class
      setTheme(state) {
        if (state.theme === "1") {
          document.body.setAttribute("class", "darkBody");
        } else {
          document.body.setAttribute("class", "defaultBody");
        }
      },
    },
    getters: {
      visitedViews: (state) => state.visitedViews,
      cachedViews: (state) => state.cachedViews,
      sidebar: (state) => state.sidebar,
      avatar: (state) => state.avatar,
      device: (state) => state.device,
    },
  };

  store.registerModule("common", commonModule);
}

export default registerCommonModule;
