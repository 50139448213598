export default {
  name: 'Page404',
  computed: {
    message() {
      return '找不到网页！';
    }
  },
  beforeRouteEnter: function (to, from, next) {
    next();
  }
};