import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("section", {
    class: "app-main",
    style: _normalizeStyle($options.styleObj)
  }, [_createVNode(_Transition, {
    name: "fade-transform",
    mode: "out-in"
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
      include: $options.cachedViews
    }, [(_openBlock(), _createBlock(_component_router_view, {
      key: $options.key
    }))], 1032 /* PROPS, DYNAMIC_SLOTS */, ["include"]))]),
    _: 1 /* STABLE */
  })], 4 /* STYLE */);
}