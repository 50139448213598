import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
// import commonDrawer from './commonDrawer.vue'
import commonPieChart from './commonPieChart.vue';
import commonColumn from './commonColumn.vue';
import * as weChatStatisticsApi from '@/api/weChatStatistics';
import { download } from '@/utils/index';
import { Decrypt } from '@/utils/cryptojs';
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 遮罩层
      loading: false,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        originDate: [],
        startDatestr: '',
        endDatestr: ''
      },
      searchFormData: [{
        prop: "originDate",
        label: "日期",
        type: "daterange",
        multiple: true,
        span: 7,
        change: val => {
          if (val?.length) {
            state.queryParams.startDatestr = val[0] + ' 00:00:00';
            state.queryParams.endDatestr = val[1] + ' 23:59:59';
          } else {
            state.queryParams.startDatestr = '';
            state.queryParams.endDatestr = '';
          }
        }
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "排名"
      }, {
        prop: "wechatGroupName",
        label: "微信群名称"
      }, {
        prop: "appealNum",
        label: "诉求数量"
      }, {
        prop: "courtsManageName",
        label: "台区经理"
      }, {
        prop: "courtsManagePhone",
        label: "联系电话"
      }],
      pieData: {
        legend: [],
        series: [],
        allCount: 0
      },
      isExport: false,
      columnData: {
        data: [],
        series: []
      }
    });
    onMounted(() => {
      getRobotList();
      getServiceInfoList();
    });
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      weChatStatisticsApi.appealList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.rows = res.data || [];
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            let courtsManageName = state.list.rows[i]['courtsManageName'];
            if (courtsManageName != null && courtsManageName != '') {
              state.list.rows[i]['courtsManageName'] = Decrypt(courtsManageName);
            }
            let courtsManagePhone = state.list.rows[i]['courtsManagePhone'];
            if (courtsManagePhone != null && courtsManagePhone != '') {
              state.list.rows[i]['courtsManagePhone'] = Decrypt(courtsManagePhone);
            }
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };

    // 查询机器人管理群信息
    const getRobotList = function () {
      weChatStatisticsApi.robotList().then(res => {
        if (res.code === 1000) {
          state.pieData.legend = res.data.legend || [];
          state.pieData.series = res.data.series || [];
          state.pieData.allCount = res.data.wechatNums || 0;
        }
      });
    };

    // 查询服务信息接受量
    const getServiceInfoList = function () {
      weChatStatisticsApi.serveiceInfoList().then(res => {
        if (res.code === 1000) {
          state.columnData.data = res.data.legend || [];
          const temp = [];
          for (let item of res.data.series) {
            temp.push(item.value);
          }
          state.columnData.series = temp;
        }
      });
    };

    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      weChatStatisticsApi.weChatStatisticsExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '微信群运行情况统计.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams.startDatestr = '';
      state.queryParams.endDatestr = '';
      state.queryParams.originDate = [];
      handleQuery();
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      getList,
      getRobotList,
      getServiceInfoList,
      downloadExport,
      handleQuery,
      resetQuery,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      commonPieChart,
      commonColumn,
      get weChatStatisticsApi() {
        return weChatStatisticsApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};