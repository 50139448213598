import {weChatrequest} from '@/utils/request'

// 诉求列表查询
export function sentimentList(data) {
    return weChatrequest({
        url: '/entry/getPublicSentiment',
        method: 'post',
        data
    })
}

// 诉求提醒
export function appealRemind(data) {
    return weChatrequest({
        url: '/appealManage/appealRemind',
        method: 'post',
        data
    })
}

// 诉求提级提醒
export function appealUpRemind(data) {
    return weChatrequest({
        url: '/appealManage/appealUpRemind',
        method: 'post',
        data
    })
}

// 诉求追踪
export function appealTrack(data) {
    return weChatrequest({
        url: '/appealManage/track',
        method: 'post',
        data
    })
}

// 办结处理
export function appealComplete(data) {
    return weChatrequest({
        url: '/appealManage/appealComplete',
        method: 'post',
        data
    })
}

export function sentimentHandle(data) {
    return weChatrequest({
        url: '/entry/sentimentHandle',
        method: 'post',
        data
    })
}

// 诉求导出
export function sentimentExport(data) {
    return weChatrequest({
        url: '/entry/exportXls/publicSentiment',
        method: 'post',
        responseType: 'blob',
        data
    })
}
// 办结数目情况
export function selectDoOrUndoTotal(data) {
    return weChatrequest({
        url: '/entry/selectDoOrUndoTotal',
        method: 'post',
        data
    })
}
export function updatePageResponseTime(data) {
    return weChatrequest({
        url: '/isc/updatePageResponseTime',
        method: 'post',
        data
    })
}