export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return [];
    },
    key() {
      return this.$route.fullPath;
    },
    isAppContainer() {
      let route = this.$route;
      if (route.path === '/index') {
        return true;
      } else {
        return false;
      }
    },
    styleObj() {
      if (this.isAppContainer) {
        return {
          'padding-top': '0px'
        };
      } else {
        // 左侧菜单
        return {
          'padding-top': '0px'
        };
      }
    }
  }
};