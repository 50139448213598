import {weChatrequest} from '@/utils/request'

// 机器人列表查询
export function robotList(data) {
    return weChatrequest({
        url: '/robot/findPage',
        method: 'post',
        data
    })
}

// 机器人管理群查询
export function robotWeChatGroup(data) {
    return weChatrequest({
        url: '/wechatGroup/robotWechatPage',
        method: 'post',
        data
    })
}

// 群消息查询
export function weChatInfo(data) {
    return weChatrequest({
        url: '/chatRecords/findPage',
        method: 'post',
        data
    })
}

// 机器人列表导出
export function robotExport(data) {
    return weChatrequest({
        url: '/robot/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}