module.exports = {
    title: '智能配网分析决策系统',
  
    /**
     * 是否系统布局配置
     */
    showSettings: false,
  
    /**
     * 是否显示 tagsView
     */
    tagsView: true,
  
    /**
     * 是否固定头部
     */
    fixedHeader: true,
  
    /**
     * 是否显示logo
     */
    sidebarLogo: false,
    //第三方登录配置
  
    /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
    errorLog: 'production',
  }
  