import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken,toLogin ,getTokenParams,accestokenByTicket} from "@/utils/auth";
NProgress.configure({ showSpinner: false });
let hasToken = window.location.href.includes("access_token");
const whiteList = [
  "/login",
  "/redirect",
  "/404",
  "/401",
];
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // console.log(getToken(),'33333333333333333333333333333333333')
  // console.log(getTokenParams(),'66666666666666666666666666666666666')
  if (!getToken() && getTokenParams()) {
    // 在没有token,并且第三方返回ticket时去换取token
    await accestokenByTicket();
  }
  if (true) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (to.path === "/@/views/login") {
        next(`/login`);
      }
      // 若没有角色，则代表未通过userId获取角色，再用角色去组装动态路由
      if (true) {
        // 获取user_info信息
//        const userInfo = await store.dispatch("common/GetInfo");
//        const deptInfo = await store.dispatch("common/GetDeptInfo");
        const accessRoutes = await store.dispatch("common/getMenu");
        // 判断是否有本系统角色或本系统路由才可进行下一步
        if(accessRoutes.length){
          accessRoutes.forEach(item=>{
            router.addRoute('layoutRoute',{
              path:item.path,
              name:item.name,
              meta:{title:item.name,icon:''},
              component:() => Promise.resolve(require(`@/views/${item.path}`).default)
            })
          })
          router.addRoute({
            path: "/:pathMatch(.*)",
            name:'error404',
            meta: { hidden: true },
            component: () => import("@/views/error/404"),
          })
          next()
        } else{
          alert('您暂无权限访问本系统，请联系管理员！')
        }
        // 判断当前用户是否已拉取完user_info信息
        // store
        //   .dispatch("GetInfo")
        //   .then(res => {
        //     // 拉取user_info
        //     const roles = res.roles;
        //     store.dispatch("GenerateRoutes", { roles }).then(accessRoutes => {
        //       // 根据roles权限生成可访问的路由表
              // next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        //     });
        //   })
        //   .catch(err => {
        //     store.dispatch("FedLogOut").then(() => {
        //       Message.error(err);
        //       next({ path: "/" });
        //     });
        //   });
      } else {
        if (hasToken) {
          hasToken = false;
          return next({ path: "/", replace: true });
        }else{
          next();
        }
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // 去登录页
      toLogin()
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

