import {weChatrequest} from '@/utils/request'

export function entryCategoryList(data) {
    return weChatrequest({
        url: '/entry/findPage',
        method: 'post',
        data
    })
}

export function entryContentList(data) {
    return weChatrequest({
        url: '/entry/list',
        method: 'post',
        data
    })
}

export function entryCategoryAdd(data) {
    return weChatrequest({
        url: '/entry/create',
        method: 'post',
        data
    })
}
export function entryContentAdd(data) {
    return weChatrequest({
        url: '/entry/createContent',
        method: 'post',
        data
    })
}
export function entryCategoryEdit(data) {
    return weChatrequest({
        url: '/entry/update',
        method: 'post',
        data
    })
}
export function entryContentEdit(data) {
    return weChatrequest({
        url: '/entry/updateContent',
        method: 'post',
        data
    })
}
export function entryCategoryDel(data) {
    return weChatrequest({
        url: '/entry/delete',
        method: 'post',
        data
    })
}
export function entryContentDel(data) {
    return weChatrequest({
        url: '/entry/deleteContent',
        method: 'post',
        data
    })
}
export function getCategorysList() {
    return weChatrequest({
        url: '/entry/allCategory',
        method: 'get'
    })
}
export function keyWordExport(data) {
    return weChatrequest({
        url: '/entry/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}

