export default {
  data() {
    return {
      loginParams: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          login(this.loginParams.username, this.loginParams.password).then(() => {
            this.$message.success('登录成功');
            this.$router.push('/');
          }).catch(error => {
            console.error(error);
            this.$message.error('登录失败');
          });
        }
      });
    }
  },
  computed: {
    loginFormValid() {
      return this.$refs.loginForm ? this.$refs.loginForm.form.valid : true;
    }
  }
};