import { createRouter, createWebHistory } from "vue-router";

/* Layout */
import Layout from "@/layout";
/**
 * Note: 路由配置项
 *
 * meta:{ hidden: true }                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect"),
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/error/404"),
    meta: { hidden: true },
  },
  {
    path: "/401",
    component: () => import("@/views/error/401"),
    meta: { hidden: true },
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    meta: { hidden: true },
  },
  {
    path: "/",
    component: Layout,
    name:'layoutRoute',
    redirect: "/weChatStatistics",
    children: [
      // {
      //   path: "courtsManage",
      //   name:'courtsManage',
      //   meta: { title: '台区管理', icon: '' },
      //   component: () => import("@/views/courtsManage"),
      // },
      // {
      //   path: "courtsPersonTopo",
      //   name:'courtsPersonTopo',
      //   meta: { title: '台区人员拓扑', icon: '' },
      //   component: () => import("@/views/courtsPersonTopo"),
      // },
      // {
      //   path: "robotManage",
      //   name:'robotManage',
      //   meta: { title: '机器人管理', icon: '' },
      //   component: () => import("@/views/robotManage"),
      // },
      // {
      //   path: "weChatGroupManage",
      //   name:'weChatGroupManage',
      //   meta: { title: '微信群管理', icon: '' },
      //   component: () => import("@/views/weChatGroupManage"),
      // },
       {
         path: "weChatStatistics",
         name:'weChatStatistics',
         meta: { title: '微信群运行情况统计', icon: '' },
         component: () => import("@/views/weChatStatistics"),
       },
      // {
      //   path: "appealManage",
      //   name:'appealManage',
      //   meta: { title: '诉求管理', icon: '' },
      //   component: () => import("@/views/appealManage"),
      // },
      // {
      //   path: "serverInfoList",
      //   name:'serverInfoList',
      //   meta: { title: '服务信息列表', icon: '' },
      //   component: () => import("@/views/serverInfoList"),
      // },
    ],
  },
];

export default createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
