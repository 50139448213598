import {weChatrequest} from '@/utils/request'

// 诉求统计数据查询
export function appealList(data) {
    return weChatrequest({
        url: '/appealManage/total',
        method: 'post',
        data
    })
}

// 机器人管理统计
export function robotList(data) {
    return weChatrequest({
        url: '/robot/total',
        method: 'post',
        data
    })
}

// 服务信息统计统计
export function serveiceInfoList(data) {
    return weChatrequest({
        url: '/information/total',
        method: 'post',
        data
    })
}

// 微信群运行情况导出
export function weChatStatisticsExport(data) {
    return weChatrequest({
        url: '/appealManage/total/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}