import {weChatrequest} from '@/utils/request'

// 诉求列表查询
export function appealList(data) {
    return weChatrequest({
        url: '/appealManage/findPage',
        method: 'post',
        data
    })
}

// 诉求提醒
export function appealRemind(data) {
    return weChatrequest({
        url: '/appealManage/appealRemind',
        method: 'post',
        data
    })
}

// 诉求提级提醒
export function appealUpRemind(data) {
    return weChatrequest({
        url: '/appealManage/appealUpRemind',
        method: 'post',
        data
    })
}

// 诉求追踪
export function appealTrack(data) {
    return weChatrequest({
        url: '/appealManage/track',
        method: 'post',
        data
    })
}

// 办结处理
export function appealComplete(data) {
    return weChatrequest({
        url: '/appealManage/appealComplete',
        method: 'post',
        data
    })
}

export function appealHandle(data) {
    return weChatrequest({
        url: '/appealManage/appealHandle',
        method: 'post',
        data
    })
}

// 诉求导出
export function appealExport(data) {
    return weChatrequest({
        url: '/appealManage/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}
// 办结数目情况
export function selectDoOrUndoTotal(data) {
    return weChatrequest({
        url: '/appealManage/selectDoOrUndoTotal',
        method: 'post',
        data
    })
}