import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as weChatApi from '@/api/weChatManage';
import { download } from '@/utils/index';
import { Decrypt } from '@/utils/cryptojs';
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 遮罩层
      loading: true,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 表单下拉关联参数
      formListParams: {
        city: '',
        deptName: ''
      },
      // 表格选中的数据
      currentSelectRows: [],
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        fourthDept: '',
        deptName: '',
        lineName: '',
        lineId: '',
        city: '',
        secondDept: '',
        wechatGroupNameOriginal: '',
        courtsName: '',
        // courtsNames: [],
        courtsManageNames: []
      },
      cloneQueryParams: null,
      searchFormData: [{
        prop: "secondDept",
        label: "所属地市",
        type: "bigData_list_multi",
        placeholder: "请选择所属地市",
        multiple: false,
        clearable: 'no',
        span: 7,
        minWidth: 270,
        disabledHandle(row) {
          return !state.roleAdmin; //可操作，其余禁用
        },

        options: [],
        props: {
          label: 'secondDept',
          value: 'secondDept'
        },
        change: val => {
          let queryParams = {
            secondDept: val
          };
          state.queryParams.city = '';
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getCityList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "city",
        label: "所属区域",
        clearable: 'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        type: "bigData_list_multi",
        placeholder: "请选择所属区域",
        multiple: false,
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          let queryParams = {
            city: val,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getDeptList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "deptName",
        label: "所属单位",
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        clearable: 'no',
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          let queryParams = {
            deptName: val,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "fourthDept",
        label: "所属班所",
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属班所",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'fourthDept',
          value: 'fourthDept'
        },
        change: val => {
          let queryParams = {
            fourthDept: val,
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getLinesList(queryParams);
          getCoutrsManage(queryParams);
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "lineName",
        label: "所属线路",
        type: "bigData_list_multi",
        placeholder: "请输入所属线路",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'lineName',
          value: 'lineId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.lineId === val);
          if (selectedOption) {
            state.queryParams.lineId = val;
            state.queryParams.lineName = selectedOption.lineName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName
            // lineId:val
          };

          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsName",
        label: "台区名称",
        type: "bigData_list_multi",
        placeholder: "请输入台区名称",
        multiple: false,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsName',
          value: 'courtsId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.courtsId === val);
          if (selectedOption) {
            state.queryParams.courtsId = val;
            state.queryParams.courtsName = selectedOption.courtsName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName,
            // lineId:state.queryParams.lineId,
            courtsName: state.queryParams.courtsName
            // courtsId:val
          };

          state.queryParams.courtsManageNames = [];
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      },
      // {
      //   prop: "courtsNames",
      //   label: "所属台区",
      //   type:"bigData_list_multi",
      //   placeholder: "请选择所属台区",
      //   multiple:true,
      //   span: 7,
      //   minWidth:270,
      //   method : "post",
      //   optionsUrl: "/wechat-group-manage/courts/getCourtsName",
      //   props:{
      //     label:'courtsName',
      //     value:'courtsName'
      //   },
      //   queryParams:{}
      // },
      {
        prop: "wechatGroupNameOriginal",
        label: "微信群名称",
        placeholder: "请输入微信群名称",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "courtsManageNames",
        label: "台区经理",
        type: "bigData_list_multi",
        placeholder: "请输入台区经理",
        multiple: true,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsManageName',
          value: 'courtsManageName'
        },
        change: (val, control) => {
          // const selectedOption = control.find((option) => option.courtsId === val);
          // state.queryParams.courtsId=val
          // state.queryParams.courtsName=selectedOption.courtsName
        },
        queryParams: {}
      },
      // {
      //   prop: "courtsManageNames",
      //   label: "台区经理",
      //   type:"bigData_list_multi",
      //   placeholder: "请选择台区经理",
      //   multiple:true,
      //   span: 7,
      //   minWidth:200,
      //   method : "post",
      //   optionsUrl: "/wechat-group-manage/courts/getCourtsManageName",
      //   props:{
      //     label:'courtsManageName',
      //     value:'courtsManageName'
      //   },
      //   queryParams:{}
      // },
      {
        prop: "isEnable",
        label: "是否启用",
        minWidth: 200,
        type: "list",
        placeholder: "请选择是否启用",
        span: 7,
        options: [{
          dictLabel: '启用',
          dictValue: '启用'
        }, {
          dictLabel: '禁用',
          dictValue: '禁用'
        }]
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: () => handleAdd()
      }, {
        buttonName: "编辑",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleEdit()
      }, {
        buttonName: "删除",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleDel()
      }, {
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "wechatGroupNameOriginal",
        label: "微信群名称"
      }, {
        prop: "wechatGroupName",
        label: "微信群备注"
      }, {
        prop: "courtsNameSet",
        label: "所属台区"
      }, {
        prop: "courtsManageName",
        label: "台区经理"
      }, {
        prop: "courtsManagePhone",
        label: "联系电话"
      }, {
        prop: "lineName",
        label: "所属线路"
      }, {
        prop: "createDateStr",
        label: "创建时间"
      }, {
        prop: "isEnable",
        label: "是否启用"
      }, {
        prop: "courtsRemarkNameRobot",
        label: "台区经理微信备注"
      }, {
        prop: "remark",
        label: "备注"
      }],
      operation: '新增',
      //操作标志位
      transName: '',
      transformer: '',
      isExport: false,
      // 表单参数
      form: {
        wechatGroupName: '',
        wechatGroupNameOriginal: null,
        robotId: null,
        city: null,
        secondDept: '',
        deptName: null,
        courtsName: null,
        fourthDept: '',
        lineName: '',
        lineId: '',
        courtsManageName: null,
        courtsManagePhone: null,
        isEnable: '启用',
        courtsRemarkNameRobot: null,
        remark: null,
        courtsId: null
      },
      // 表单校验
      rules: {
        wechatGroupNameOriginal: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        // wechatGroupName : [{ required: true, message: "不能为空", trigger: "change" }],
        deptName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        // lineName: [{ required: true, message: "不能为空", trigger: "change" }],
        // fourthDept: [{ required: true, message: "不能为空", trigger: "change" }],
        city: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        courtsName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        isEnable: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }]
      },
      formData: [{
        prop: "wechatGroupNameOriginal",
        label: "微信群名称",
        placeholder: "请输入微信群名称",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "wechatGroupName",
        label: "微信群备注",
        placeholder: "请输入微信群备注",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "robotId",
        label: "所属机器人",
        type: "list",
        placeholder: "请选择所属机器人",
        method: "get",
        span: 24,
        optionsUrl: "/wechat-group-manage/robot/getRobot",
        props: {
          label: 'robotName',
          value: 'robotId'
        },
        queryParams: {}
      }, {
        prop: "secondDept",
        label: "所属地市",
        type: "bigData_list_multi",
        placeholder: "请选择所属地市",
        multiple: false,
        clearable: 'no',
        span: 24,
        disabledHandle(row) {
          return !state.roleAdmin; //可操作，其余禁用
        },

        options: [],
        props: {
          label: 'secondDept',
          value: 'secondDept'
        },
        change: val => {
          let queryParams = {
            secondDept: val
          };
          state.form.city = '';
          state.form.deptName = '';
          state.form.lineName = '';
          state.form.lineId = '';
          state.form.courtsName = '';
          state.form.fourthDept = '';
          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getCityList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "city",
        label: "所属区域",
        clearable: 'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        type: "bigData_list_multi",
        placeholder: "请选择所属区域",
        multiple: false,
        span: 24,
        options: [],
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          let queryParams = {
            city: val,
            secondDept: state.form.secondDept
          };
          state.form.deptName = '';
          state.form.lineName = '';
          state.form.lineId = '';
          state.form.courtsName = '';
          state.form.fourthDept = '';
          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getDeptList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "deptName",
        label: "所属单位",
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        clearable: 'no',
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 24,
        options: [],
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          let queryParams = {
            deptName: val,
            city: state.form.city,
            secondDept: state.form.secondDept
          };
          state.form.lineName = '';
          state.form.lineId = '';
          state.form.courtsName = '';
          state.form.fourthDept = '';
          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "fourthDept",
        label: "所属班所",
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属班所",
        span: 24,
        options: [],
        props: {
          label: 'fourthDept',
          value: 'fourthDept'
        },
        change: val => {
          let queryParams = {
            fourthDept: val,
            deptName: state.form.deptName,
            city: state.form.city,
            secondDept: state.form.secondDept
          };
          state.form.lineName = '';
          state.form.lineId = '';
          state.form.courtsName = '';
          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getLinesList(queryParams);
          getCoutrsManage(queryParams);
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "lineName",
        label: "所属线路",
        type: "bigData_list_multi",
        placeholder: "请输入所属线路",
        span: 24,
        options: [],
        props: {
          label: 'lineName',
          value: 'lineId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.lineId === val);
          if (selectedOption) {
            state.form.lineId = val;
            state.form.lineName = selectedOption.lineName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.form.lineName
            // lineId:val
          };

          state.form.courtsName = '';
          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsName",
        label: "所属台区",
        type: "bigData_list_multi",
        placeholder: "请输入所属台区",
        multiple: false,
        span: 24,
        options: [],
        props: {
          label: 'courtsName',
          value: 'courtsId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.courtsId === val);
          if (selectedOption) {
            state.form.courtsId = val;
            state.form.courtsName = selectedOption.courtsName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName,
            // lineId:state.queryParams.lineId,
            courtsName: state.form.courtsName
            // courtsId:val
          };

          state.form.courtsManageName = '';
          state.form.courtsManagePhone = '';
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsManageName",
        label: "台区经理",
        type: "bigData_list_multi",
        placeholder: "请选择台区经理",
        span: 24,
        options: [],
        props: {
          label: 'courtsManageName',
          value: 'courtsManageName'
        },
        change: (val, control) => {
          console.log(control, val);
          const selectedOption = control.find(option => option.courtsManageName === val);
          if (selectedOption) {
            state.form.courtsManagePhone = selectedOption.courtsManagePhone;
          }
        }
        // disabled:true
      }, {
        prop: "courtsManagePhone",
        label: "联系电话",
        type: "text",
        placeholder: "",
        disabled: true,
        span: 24
        // props:{
        //   label:'courtsManagePhone',
        //   value:'courtsId'
        // },
        // options:[]
      }, {
        prop: "isEnable",
        label: "是否启用",
        type: "list",
        placeholder: "请选择是否启用",
        span: 24,
        options: [{
          dictLabel: '启用',
          dictValue: '启用'
        }, {
          dictLabel: '禁用',
          dictValue: '禁用'
        }]
      }, {
        prop: "courtsRemarkNameRobot",
        label: "台区经理微信备注",
        placeholder: "请输入机器人好友下的台区经理微信备注名",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea",
        span: 24
      }],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      dialogVisible: false,
      roleAdmin: false,
      rolejzbl: false,
      typeUrl: ''
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    onMounted(() => {
      getCityList();
      // getCourtsList();
      // getDeptList();
      if (sessionStorage.getItem('isAdmin') == "false") {
        state.roleAdmin = false;
        let roleInfo = sessionStorage.getItem('roleInfo');
        let currentDept = sessionStorage.getItem('currentDept');
        // wgwxbsz班所  wgwxkhjl客户经理  wgwxfwzz专责 --currentDept--接口 根据单位反查currentDept
        // wgwxjzbl省市集中办理--currentDept--市
        if (roleInfo == "wgwxkhjl" || roleInfo == "wgwxfwzz" || roleInfo == "wgwxbsz") {
          state.rolejzbl = false;
          state.queryParams.deptName = currentDept;
          state.form.deptName = currentDept;
          weChatApi.formSecondDept({
            currentDept: currentDept
          }).then(res => {
            state.searchFormData[0].options = res.data || [];
            state.formData[3].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            state.form.secondDept = res.data[0].secondDept;
            let queryParams = {
              secondDept: res.data[0].secondDept,
              currentDept: currentDept
            };
            weChatApi.formCity(queryParams).then(res => {
              state.searchFormData[1].options = res.data || [];
              state.formData[4].options = res.data || [];
              state.queryParams.city = res.data[0].city;
              state.form.city = res.data[0].city;
              let queryParams = {
                deptName: state.queryParams.deptName,
                city: state.queryParams.city,
                secondDept: state.queryParams.secondDept
              };
              getFourtList(queryParams);
              getLinesList(queryParams);
              getCourtsList(queryParams);
              getCoutrsManage(queryParams);
              getList();
            });
          });
        }
        if (roleInfo == "wgwxjzbl") {
          state.rolejzbl = true;
          weChatApi.formSecondDept().then(res => {
            state.searchFormData[0].options = res.data || [];
            state.formData[3].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            state.form.secondDept = res.data[0].secondDept;
            getCityList1({
              secondDept: res.data[0].secondDept
            });
            // getList()
          });
        }
      } else {
        state.roleAdmin = true;
        state.rolejzbl = true;
        getSecondDeptList();
        // getList()
      }
    });

    const handleSelectionChange = function (val) {
      state.currentSelectRows = val;
      if (val.length === 1) {
        state.buttonsList[1].disabled = false;
      } else {
        state.buttonsList[1].disabled = true;
      }
      if (val.length) {
        state.buttonsList[2].disabled = false;
      } else {
        state.buttonsList[2].disabled = true;
      }
    };
    const getSecondDeptList = function (params = {}) {
      weChatApi.formSecondDept(params).then(res => {
        state.searchFormData[0].options = res.data || [];
        state.formData[3].options = res.data || [];
        state.form.secondDept = res.data[0].secondDept;
        if (!state.queryParams.secondDept || !state.form.secondDept) {
          state.queryParams.secondDept = res.data[0].secondDept;
          state.form.secondDept = res.data[0].secondDept;
          getCityList1({
            secondDept: state.queryParams.secondDept
          });
        }
      });
    };
    const getCityList1 = function (params = {}) {
      weChatApi.formCity(params).then(res => {
        state.searchFormData[1].options = res.data || [];
        state.formData[4].options = res.data || [];
        if (!state.queryParams.city || !state.form.city) {
          state.queryParams.city = res.data[0].city;
          state.form.city = res.data[0].city;
          getDeptList1({
            city: state.queryParams.city
          });
        }
      });
    };
    const getDeptList1 = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.searchFormData[2].options = res.data || [];
        state.formData[5].options = res.data || [];
        if (!state.queryParams.deptName || !state.form.deptName) {
          state.queryParams.deptName = res.data[0].deptName;
          state.form.deptName = res.data[0].deptName;
          let queryParams = {
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          getList(state.queryParams);
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
          getCoutrsManage(queryParams);
        }
      });
    };
    const getFourtList = function (params = {}) {
      weChatApi.formFourth(params).then(res => {
        state.searchFormData[3].options = res.data || [];
        state.formData[6].options = res.data || [];
      });
    };
    const getLinesList = function (params = {}) {
      weChatApi.formLines(params).then(res => {
        state.searchFormData[4].options = res.data || [];
        state.formData[7].options = res.data || [];
      });
    };
    const getCoutrsManage = function (params = {}) {
      weChatApi.formCoutrsManage(params).then(res => {
        state.searchFormData[7].options = res.data || [];
        state.formData[9].options = res.data || [];
      });
    };

    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      weChatApi.weChatList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.total = res.data.total || 0;
          state.list.rows = res.data.records || [];
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            /** let courtsManageName=state.list.rows[i]['courtsManageName']
            if(courtsManageName!=null && courtsManageName!=''){
              state.list.rows[i]['courtsManageName']=Decrypt(courtsManageName)
            }
            let courtsManagePhone=state.list.rows[i]['courtsManagePhone']
            if(courtsManagePhone!=null && courtsManagePhone!=''){
              state.list.rows[i]['courtsManagePhone']=Decrypt(courtsManagePhone)
            }*/
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      weChatApi.weChatExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '微信群列表.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    // 查询地市下拉
    const getCityList = function () {
      weChatApi.formCity().then(res => {
        if (res.code === 1000) {
          state.formData[2].options = res.data || [];
        }
      });
    };
    // 查询单位下拉
    const getDeptList = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.formData[3].options = res.data || [];
      });
    };
    // 查询台区下拉
    const getCourtsList = function (params = {}) {
      weChatApi.formCourts(params).then(res => {
        if (res.data instanceof Array) {
          for (let item of res.data) {
            if (item.courtsManageName != null && item.courtsManageName != '') {
              item.courtsManageName = Decrypt(item.courtsManageName);
            }
            if (item.courtsManagePhone != null && item.courtsManagePhone != '') {
              item.courtsManagePhone = Decrypt(item.courtsManagePhone);
            }
          }
        }
        // state.formData[4].options=res.data||[]
        // state.formData[5].options=res.data||[]
        // state.formData[6].options=res.data||[]
        state.searchFormData[5].options = res.data || [];
        state.formData[8].options = res.data || [];
      });
    };
    const handleAdd = function () {
      state.operation = '新增';
      state.form = {
        wechatGroupName: '',
        wechatGroupNameOriginal: null,
        robotId: null,
        city: null,
        secondDept: '',
        deptName: null,
        courtsName: null,
        fourthDept: '',
        lineName: '',
        lineId: '',
        courtsManageName: null,
        courtsManagePhone: null,
        isEnable: '启用',
        courtsRemarkNameRobot: null,
        remark: null,
        courtsId: null
      };
      if (sessionStorage.getItem('isAdmin') == "false") {
        state.roleAdmin = false;
        let roleInfo = sessionStorage.getItem('roleInfo');
        let currentDept = sessionStorage.getItem('currentDept');
        // wgwxbsz班所  wgwxkhjl客户经理  wgwxfwzz专责 --currentDept--接口 根据单位反查currentDept
        // wgwxjzbl省市集中办理--currentDept--市
        if (roleInfo == "wgwxkhjl" || roleInfo == "wgwxfwzz" || roleInfo == "wgwxbsz") {
          state.rolejzbl = false;
          state.form.deptName = currentDept;
          weChatApi.formSecondDept({
            currentDept: currentDept
          }).then(res => {
            state.formData[3].options = res.data || [];
            state.form.secondDept = res.data[0].secondDept;
            let queryParams = {
              secondDept: res.data[0].secondDept,
              currentDept: currentDept
            };
            weChatApi.formCity(queryParams).then(res => {
              state.formData[4].options = res.data || [];
              state.form.city = res.data[0].city;
              let queryParams = {
                deptName: state.queryParams.deptName,
                city: state.queryParams.city,
                secondDept: state.queryParams.secondDept
              };
              getFourtList(queryParams);
              getLinesList(queryParams);
              getCourtsList(queryParams);
              getCoutrsManage(queryParams);
            });
          });
        }
        if (roleInfo == "wgwxjzbl") {
          state.rolejzbl = true;
          weChatApi.formSecondDept().then(res => {
            state.formData[3].options = res.data || [];
            state.form.secondDept = res.data[0].secondDept;
            getCityList1({
              secondDept: res.data[0].secondDept
            });
          });
        }
      } else {
        state.roleAdmin = true;
        state.rolejzbl = true;
        getSecondDeptList();
        // getList()
      }

      state.dialogVisible = true;
      state.title = "新增微信群";
      nextTick(() => {
        const formRef = unref(formContainer);
        formRef.commonForm.resetFields();
      });
    };
    const handleEdit = function () {
      if (state.currentSelectRows.length > 1) {
        ElMessage.closeAll();
        ElMessage.warning('请选择一条数据进行编辑操作！');
        return;
      }
      state.operation = '编辑';
      state.form = JSON.parse(JSON.stringify(state.currentSelectRows[0]));
      // 台区、台区经理、台区经理电话绑定的value为台区id 需要在编辑前做数据整理
      // state.form.courtsName=state.form.courtsId
      // state.form.courtsManageName=state.form.courtsId
      // state.form.courtsManagePhone=state.form.courtsId
      // 组装参数 重新拉取下拉框选项值
      getCourtsList({
        city: state.form.city,
        deptName: state.form.deptName
      });
      getDeptList({
        city: state.form.city
      });
      state.dialogVisible = true;
      state.title = "编辑微信群";
    };
    const handleDel = function () {
      const ids = [];
      for (let item of state.currentSelectRows) {
        ids.push(item.id);
      }
      const formData = new FormData();
      formData.append('ids', ids);
      weChatApi.weChatDel(formData).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          ElMessage.success('删除成功');
          handleQuery();
        }
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.queryParams.pageNum = 1;
      getSecondDeptList();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          let queryParams = JSON.parse(JSON.stringify(state.form));
          if (!queryParams.wechatGroupName) {
            queryParams.wechatGroupName = queryParams.wechatGroupNameOriginal;
          }
          if (state.operation === '新增') {
            weChatApi.weChatAdd(queryParams).then(res => {
              if (res.code === 1000) {
                ElMessage.closeAll();
                ElMessage.success('新增成功');
                clearForm();
                handleQuery();
              }
            });
          } else {
            weChatApi.weChatEdit(queryParams).then(res => {
              if (res.code === 1000) {
                ElMessage.closeAll();
                ElMessage.success('编辑成功');
                clearForm();
                handleQuery();
              }
            });
          }
        }
      });
    };
    const clearForm = function () {
      state.dialogVisible = false;
      state.operation = '新增';
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      handleSelectionChange,
      getSecondDeptList,
      getCityList1,
      getDeptList1,
      getFourtList,
      getLinesList,
      getCoutrsManage,
      getList,
      downloadExport,
      getCityList,
      getDeptList,
      getCourtsList,
      handleAdd,
      handleEdit,
      handleDel,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get weChatApi() {
        return weChatApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};