import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "login-form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    ref: "loginForm",
    model: $data.loginParams,
    rules: $data.loginRules,
    class: _normalizeClass({
      'form-invalid': !$options.loginFormValid
    })
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "用户名",
      prop: "username"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.loginParams.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.loginParams.username = $event),
        placeholder: "请输入用户名"
      }, null, 8 /* PROPS */, ["modelValue"])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_el_form_item, {
      label: "密码",
      prop: "password"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.loginParams.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.loginParams.password = $event),
        type: "password",
        placeholder: "请输入密码"
      }, null, 8 /* PROPS */, ["modelValue"])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.handleSubmit
      }, {
        default: _withCtx(() => [_createTextVNode("登录")]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])]),
      _: 1 /* STABLE */
    })]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model", "rules", "class"])]);
}