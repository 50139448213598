import {weChatrequest} from '@/utils/request'

// 台区列表查询
export function courtsList(data) {
    return weChatrequest({
        url: '/courts/findPage',
        method: 'post',
        data
    })
}

// 台区列表导出
export function courtsExport(data) {
    return weChatrequest({
        url: '/courts/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 台区列表同步
export function courtsSynchronous() {
    return weChatrequest({
        url: '/courts/dataynch',
        method: 'post',
    })
}

// 台区经理手机号编辑
export function courtsUpdatePhone(data) {
    return weChatrequest({
        url: '/courts/update',
        method: 'post',
        data
    })
}
