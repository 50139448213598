import RightPanel from "../components/RightPanel";
import { AppMain, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import Screenfull from "../components/Screenfull";
export default {
  name: "Layout",
  components: {
    AppMain,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    Screenfull
  },
  props: {},
  data() {
    return {
      iframeData: new Map([["/test1", "https://lanhuapp.com/web/#/item/project/protoDetach/edit/entry?sId=42b648ca-52ba-4b9e-bd6b-eb1950e8e008&pid=bc4c4108-8d6f-4020-ac2c-ebaf13d8311c&type=web"]])
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.common.sidebar,
      device: state => state.common.device,
      showSettings: state => state.common.showSettings,
      needTagsView: state => state.common.tagsView,
      fixedHeader: state => state.common.fixedHeader,
      visitedViews: state => state.common.visitedViews,
      userInfo: state => state.common.userInfo,
      deptInfo: state => state.common.deptInfo
    }),
    showSideBar() {
      if (this.$route.path !== "/index") {
        return true;
      } else {
        return false;
      }
    },
    showRoleInfo() {
      var roleName = '';
      if (sessionStorage.getItem('isAdmin') == "false") {
        var roleInfo = sessionStorage.getItem('roleInfo');
        if (roleInfo == "wgwxkhjl") {
          roleName = '客户经理';
        } else if (roleInfo == "wgwxfwzz") {
          roleName = '区县专责';
        } else if (roleInfo == "wgwxbsz") {
          roleName = '班所长';
        } else if (roleInfo == "wgwxjzbl") {
          roleName = '省市集中办理';
        }
      }
      return roleName;
    },
    showIsAdmin() {
      const isadmin = this.isAdminOne();
      return isadmin == "false" ? this.currentDept() : '管理员';
    },
    cachedViews() {
      return this.$store.state.common.cachedViews;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    setting: {
      get() {
        return this.$store.state.common.showSettings;
      },
      set(val) {
        this.$store.dispatch("common/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    },
    currentIframe() {
      let path = this.$route.path;
      if (this.iframeData.get(path)) {
        return {
          src: this.iframeData.get(path)
        };
      } else {
        return false;
      }
    },
    iframeStyle() {
      return {
        width: "100%",
        padding: "16px",
        height: "100%",
        overflow: "hidden",
        border: 0
      };
    }
  },
  created() {},
  mounted() {
    window.onresize = () => {
      if (document.documentElement.clientWidth < 1020) {
        this.$store.state.common.sidebar.opened = false;
      } else {
        this.$store.state.common.sidebar.opened = true;
      }
    };
  },
  watch: {},
  methods: {
    isAdminOne() {
      return sessionStorage.getItem('isAdmin');
    },
    currentDept() {
      return sessionStorage.getItem('currentDept');
    },
    // 侧边栏展开收起
    toggleSideBar() {
      this.$store.dispatch("common/toggleSideBar");
    },
    handleClickOutside() {
      this.$store.dispatch("common/closeSideBar", {
        withoutAnimation: false
      });
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("common/LogOut").then(() => {
          location.reload();
        });
      });
    }
  }
};