import {weChatrequest} from '@/utils/request'

// 登录方法
export function login(username, ppww, code, uuid) {
  const data = {
    username,
    ppww,
    code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    params: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return new Promise(function (reslove) {
    window.location.href ='/isc_sso/logout'
    reslove()
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// 获取路由
export const getRouters = (roleId) => {
  return weChatrequest({
    url: `/isc/${roleId}`,
    method: 'get',
  })
}
export const getISCCode = (data) => {
  return weChatrequest({
    url: `/courts/getOneCourts`,
    method: 'post',
    data
  })
}
// 获取路由
export const justAdmin = (data) => {
  return weChatrequest({
    url: '/isc/getUsersByRoleId',
    method: 'post',
    data
  })
}

// 获取角色
export const getRole = (data) => {
  return weChatrequest({
    url: '/isc/getObjectsByUserId',
    method: 'post',
    data
  })
}

// 获取组织机构信息
export const getDeptInfo = (data) => {
  return weChatrequest({
    url: '/isc/getBusiOrgByUserId',
    method: 'post',
    data
  })
}

// 更新Token
export const updateToken = (data) => {
  return weChatrequest({
    url: '/isc/updateTokenToRedis',
    method: 'post',
    data
  })
}
