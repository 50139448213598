import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission"; // permission control
import "@/assets/styles/variables.scss"; // 全局样式
import "@/assets/sass/index.scss"; // 全局样式
import "@/assets/iconFonts/iconfont.css"; //引入iconfont字体图标

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Iconfont from "@/components/iconfont"; // 全局自定义组件
import Directive from "@/directive"; // 自定义指令
import { constantRoutes } from "@/router";
import registerCommonModule from "@/store/commonRegister";
import * as ruoyi from "@/utils/lzCommon.js";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
registerCommonModule(store, {}, constantRoutes);
const app = createApp(App);
app.config.globalProperties.ruoyi = ruoyi;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}


app.component("Iconfont", Iconfont);
app
  .use(router)
  .use(store)
  .use(Directive)
  .use(ElementPlus, { locale: zhCn })
  .mount("#app");
