import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as appealApi from '@/api/appealManage';
import * as sentimentApi from '@/api/sentimentMonitor';
import * as weChatApi from '@/api/weChatManage';
import { download } from '@/utils/index';
import { Decrypt } from '@/utils/cryptojs';
// import commonDrawer from './commonDrawer.vue'

export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 聊天记录列表
      infoList: [],
      infoListLoading: false,
      appealTrackInfo: {
        unit: '',
        person: '',
        phone: '',
        groupName: ''
      },
      undo: 0,
      wjr: 0,
      tyj: 0,
      tej: 0,
      tsj: 0,
      tjz: 0,
      does: 0,
      sjbj: 0,
      ejbj: 0,
      yjbj: 0,
      jzbj: 0,
      jqrbj: 0,
      pretimestamp: 0,
      nexttimestamp: 0,
      // 聊天记录查询条件
      infoListQueryParams: {
        pageNum: 1,
        pageSize: 20
      },
      // 遮罩层
      loading: false,
      isExport: false,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        fourthDept: '',
        deptName: '',
        city: '',
        secondDept: '',
        startDatestr: '',
        endDatestr: '',
        appealContent: '',
        emergentLevel: '',
        wechatName: '',
        handleType: '',
        originDate: [],
        courtsManageNames: []
      },
      cloneQueryParams: null,
      searchFormData: [{
        prop: "secondDept",
        label: "所属地市",
        type: "bigData_list_multi",
        placeholder: "请选择所属地市",
        multiple: false,
        clearable: 'no',
        disabledHandle(row) {
          return !state.roleAdmin; //可操作，其余禁用
        },

        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'secondDept',
          value: 'secondDept'
        },
        change: val => {
          let queryParams = {
            secondDept: val
          };
          state.queryParams.city = '';
          state.queryParams.deptName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getCityList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "city",
        label: "所属区域",
        clearable: 'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        type: "bigData_list_multi",
        placeholder: "请选择所属区域",
        multiple: false,
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          let queryParams = {
            city: val,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.deptName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getDeptList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "deptName",
        label: "所属单位",
        clearable: 'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          let queryParams = {
            deptName: val,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getFourtList(queryParams);
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "fourthDept",
        label: "所属班所",
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属班所",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'fourthDept',
          value: 'fourthDept'
        },
        change: val => {
          let queryParams = {
            fourthDept: val,
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getCoutrsManage(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsManageNames",
        label: "台区经理",
        type: "bigData_list_multi",
        placeholder: "请输入台区经理",
        multiple: true,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsManageName',
          value: 'courtsManageName'
        },
        change: (val, control) => {
          // const selectedOption = control.find((option) => option.courtsId === val);
          // state.queryParams.courtsId=val
          // state.queryParams.courtsName=selectedOption.courtsName
        },
        queryParams: {}
      }, {
        prop: "appealContent",
        label: "用户诉求",
        placeholder: "请输入用户诉求",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "handleType",
        label: "诉求状态",
        type: "list",
        placeholder: "请输入诉求状态",
        minWidth: 200,
        span: 7,
        options: [{
          dictLabel: '未办',
          dictValue: '100'
        }, {
          dictLabel: '已办',
          dictValue: '200'
        }, {
          dictLabel: '未介入',
          dictValue: '0'
        }, {
          dictLabel: '提三级',
          dictValue: '1'
        }, {
          dictLabel: '提二级',
          dictValue: '3'
        }, {
          dictLabel: '提一级',
          dictValue: '5'
        }, {
          dictLabel: '提集中',
          dictValue: '7'
        }, {
          dictLabel: '三级办结',
          dictValue: '2'
        }, {
          dictLabel: '二级办结',
          dictValue: '4'
        }, {
          dictLabel: '一级办结',
          dictValue: '6'
        }, {
          dictLabel: '集中办结',
          dictValue: '8'
        }, {
          dictLabel: '机器人办结',
          dictValue: '9'
        }]
      },
      /**{
        prop: "emergentLevel",
        label: "紧急程度",
        type:"list",
        placeholder: "请输入紧急程度",
        span: 7,
        options:[{
          dictLabel:'1级(复电咨询类)',
          dictValue:'1级(复电咨询类)'
        },{
          dictLabel:'2级(业务办理类流程)',
          dictValue:'2级(业务办理类流程)'
        },{
          dictLabel:'3级(政策了解类)',
          dictValue:'3级(政策了解类)'
        },{
          dictLabel:'4级(其他)',
          dictValue:'4级(其他)'
        }]
      },*/
      {
        prop: "wechatName",
        label: "用户微信昵称",
        placeholder: "请输入用户微信昵称",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "originDate",
        label: "用户提交诉求时间",
        type: "daterange",
        multiple: true,
        span: 7,
        change: val => {
          if (val?.length) {
            state.queryParams.startDatestr = val[0] + ' 00:00:00';
            state.queryParams.endDatestr = val[1] + ' 23:59:59';
          } else {
            state.queryParams.startDatestr = '';
            state.queryParams.endDatestr = '';
          }
        }
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "appealContent",
        label: "用户诉求"
      }, {
        prop: "emergentLevel",
        label: "紧急程度"
      }, {
        prop: "wechatName",
        label: "用户微信昵称"
      }, {
        prop: "createDateStr",
        label: "用户诉求提交时间"
      }, {
        prop: "wechatGroupNameOriginal",
        label: "微信群名称"
      }, {
        prop: "courtsName",
        label: "所属台区"
      }, {
        prop: "courtsManageName",
        label: "台区经理"
      }, {
        prop: "courtsManagePhone",
        label: "联系电话"
      }, {
        prop: "textIsHandle",
        label: "诉求状态"
      }, {
        prop: "operation",
        label: "操作",
        width: "300px",
        fixed: "right",
        operation: [
        /**(sessionStorage.getItem('roleInfo')=='wgwxfwzz' || sessionStorage.getItem('isAdmin')=='true')?
         {
           name: '诉求提醒',
           clickFun: (row) => appealRemind(row),
         }:{},*/
        {
          name: '提级转办',
          clickFun: row => appealUpHandle(row),
          hideHandle(row) {
            //debugger
            let status = [1, 3, 5];
            if (sessionStorage.getItem('roleInfo') == 'wgwxkhjl' && row.handleType == 1 || sessionStorage.getItem('roleInfo') == 'wgwxbsz' && row.handleType == 3 || sessionStorage.getItem('roleInfo') == 'wgwxfwzz' && row.handleType == 5 || sessionStorage.getItem('isAdmin') == 'true' && status.includes(row.handleType)) {
              return false;
            } else {
              return true;
            }
          }
        }, {
          name: '诉求追踪',
          clickFun: row => appealTrack(row)
        }, {
          name: '办结',
          clickFun: row => appealComplete(row),
          hideHandle(row) {
            let status = [1, 3, 5, 7];
            if (sessionStorage.getItem('roleInfo') == 'wgwxkhjl' && row.handleType == 1 || sessionStorage.getItem('roleInfo') == 'wgwxbsz' && row.handleType == 3 || sessionStorage.getItem('roleInfo') == 'wgwxfwzz' && row.handleType == 5 || sessionStorage.getItem('roleInfo') == 'wgwxjzbl' && row.handleType == 7 || sessionStorage.getItem('isAdmin') == 'true' && status.includes(row.handleType)) {
              return false;
            } else {
              return true;
            }
          }
        }]
      }],
      // 是否显示弹出层
      dialogVisible: false,
      roleAdmin: false,
      rolejzbl: false
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    onMounted(() => {
      selectDoOrUndoTotal();
      if (sessionStorage.getItem('isAdmin') == "false") {
        state.roleAdmin = false;
        let roleInfo = sessionStorage.getItem('roleInfo');
        let currentDept = sessionStorage.getItem('currentDept');
        // wgwxbsz班所  wgwxkhjl客户经理  wgwxfwzz专责 --currentDept--接口 根据单位反查currentDept
        // wgwxjzbl省市集中办理--currentDept--市
        if (roleInfo == "wgwxkhjl" || roleInfo == "wgwxfwzz" || roleInfo == "wgwxbsz") {
          state.rolejzbl = false;
          state.queryParams.deptName = currentDept;
          weChatApi.formSecondDept({
            currentDept: currentDept
          }).then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            let queryParams = {
              secondDept: res.data[0].secondDept,
              currentDept: currentDept
            };
            weChatApi.formCity(queryParams).then(res => {
              state.searchFormData[1].options = res.data || [];
              state.queryParams.city = res.data[0].city;
              let queryParams = {
                deptName: state.queryParams.deptName,
                city: state.queryParams.city,
                secondDept: state.queryParams.secondDept
              };
              getFourtList(queryParams);
              getCoutrsManage(queryParams);
              getList();
            });
          });
        }
        if (roleInfo == "wgwxjzbl") {
          state.rolejzbl = true;
          weChatApi.formSecondDept().then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            getCityList1({
              secondDept: res.data[0].secondDept
            });
            // getList()
          });
        }
      } else {
        state.roleAdmin = true;
        state.rolejzbl = true;
        getSecondDeptList();
        // getList()
      }
    });

    const selectDoOrUndoTotal = function () {
      appealApi.selectDoOrUndoTotal(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.undo = res.data.未办 || 0;
          state.wjr = res.data.未介入 || 0;
          state.tsj = res.data.提三级 || 0;
          state.tej = res.data.提二级 || 0;
          state.tyj = res.data.提一级 || 0;
          state.tjz = res.data.提集中 || 0;
          state.does = res.data.已办 || 0;
          state.sjbj = res.data.三级办结 || 0;
          state.ejbj = res.data.二级办结 || 0;
          state.yjbj = res.data.一级办结 || 0;
          state.jzbj = res.data.集中办结 || 0;
          state.jqrbj = res.data.机器人办结 || 0;
        }
      });
    };
    const updatePageResponseTime = function (diff) {
      sentimentApi.updatePageResponseTime({
        diffResponseTime: diff
      }).then(res => {
        if (res.code === 1000) {}
      });
    };
    const getSecondDeptList = function (params = {}) {
      weChatApi.formSecondDept(params).then(res => {
        state.searchFormData[0].options = res.data || [];
        if (!state.queryParams.secondDept) {
          state.queryParams.secondDept = res.data[0].secondDept;
          getCityList1({
            secondDept: state.queryParams.secondDept
          });
        }
      });
    };
    const getCityList1 = function (params = {}) {
      weChatApi.formCity(params).then(res => {
        state.searchFormData[1].options = res.data || [];
        if (!state.queryParams.city) {
          state.queryParams.city = res.data[0].city;
          getDeptList1({
            city: state.queryParams.city
          });
        }
      });
    };
    const getDeptList1 = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.searchFormData[2].options = res.data || [];
        if (!state.queryParams.deptName) {
          state.queryParams.deptName = res.data[0].deptName;
          let queryParams = {
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          getList(state.queryParams);
          getFourtList(queryParams);
          getCoutrsManage(queryParams);
        }
      });
    };
    const getFourtList = function (params = {}) {
      weChatApi.formFourth(params).then(res => {
        state.searchFormData[3].options = res.data || [];
      });
    };
    const getCoutrsManage = function (params = {}) {
      weChatApi.formCoutrsManage(params).then(res => {
        state.searchFormData[4].options = res.data || [];
      });
    };
    /** 查询模型列表 */
    const getList = function () {
      state.pretimestamp = Date.now();
      state.loading = true;
      appealApi.appealList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.total = res.data.total || 0;
          state.list.rows = res.data.records || [];
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            let courtsManageName = state.list.rows[i]['courtsManageName'];
            if (courtsManageName != null && courtsManageName != '') {
              state.list.rows[i]['courtsManageName'] = Decrypt(courtsManageName);
            }
            let courtsManagePhone = state.list.rows[i]['courtsManagePhone'];
            if (courtsManagePhone != null && courtsManagePhone != '') {
              state.list.rows[i]['courtsManagePhone'] = Decrypt(courtsManagePhone);
            }
          }
          state.nexttimestamp = Date.now();
          updatePageResponseTime(state.nexttimestamp - state.pretimestamp);
        }
      }).finally(() => {
        state.loading = false;
      });
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      appealApi.appealExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '诉求列表.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.queryParams.pageNum = 1;
      getSecondDeptList();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {}
      });
    };
    const clearForm = function () {
      state.dialogVisible = false;
    };
    const appealRemind = function (row) {
      appealApi.appealRemind({
        id: row.id
      }).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          ElMessage.success('提醒成功');
        } else {
          ElMessage.closeAll();
          ElMessage.warning(res.message);
        }
      });
    };
    const appealUpRemind = function (row) {
      appealApi.appealUpRemind({
        id: row.id
      }).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          handleQuery();
          selectDoOrUndoTotal();
          ElMessage.success('提醒成功');
        } else {
          ElMessage.closeAll();
          ElMessage.warning(res.message);
        }
      });
    };
    const appealTrack = function (row) {
      state.appealTrackInfo = {
        unit: row.depyName,
        person: row.courtsManageName,
        phone: row.courtsManagePhone,
        groupName: row.wechatGroupName
      };
      state.infoListQueryParams.pageNum = 1;
      getSearchInfo(row);
    };
    const appealComplete = function (row) {
      row.doType = 'cur';
      appealApi.appealHandle(row).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          handleQuery();
          selectDoOrUndoTotal();
          ElMessage.success('办结处理成功');
        } else {
          ElMessage.closeAll();
          ElMessage.warning(res.message);
        }
      });
    };
    const appealUpHandle = function (row) {
      row.doType = 'up';
      appealApi.appealHandle(row).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          handleQuery();
          selectDoOrUndoTotal();
          ElMessage.success('提级转办成功！');
        } else {
          ElMessage.closeAll();
          ElMessage.warning(res.message);
        }
      });
    };
    const getSearchInfo = function (row) {
      state.infoListLoading = true;
      state.infoList = [];
      appealApi.appealTrack({
        id: row.id
      }).then(res => {
        state.infoList = res.data || [];
        state.dialogVisible = true;
      }).finally(() => {
        state.infoListLoading = false;
      });
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      selectDoOrUndoTotal,
      updatePageResponseTime,
      getSecondDeptList,
      getCityList1,
      getDeptList1,
      getFourtList,
      getCoutrsManage,
      getList,
      downloadExport,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      appealRemind,
      appealUpRemind,
      appealTrack,
      appealComplete,
      appealUpHandle,
      getSearchInfo,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get appealApi() {
        return appealApi;
      },
      get sentimentApi() {
        return sentimentApi;
      },
      get weChatApi() {
        return weChatApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};