/*
 * @Description:
 * @Author: gumingchen
 * @Email: 1240235512@qq.com
 * @Date: 2021-04-02 18:59:43
 * @LastEditors: gumingchen
 * @LastEditTime: 2021-04-18 09:16:20
 */
import {
  getActiveMenu,
  setActiveMenu,
  getGet,
  setGet,
  clearGet,
  setMenuAndPermission,
  clearMenuAndPermission,
  getMenuType,
  setMenuType,
} from "@/utils/storage";
import { getRouters } from "@/api/login";

// 初始化菜单 权限 数据

/**
 * @description: 递归筛选出 目录、菜单
 * @param {Array} list
 * @param {Number} mode 1-显示的 2-所有的
 * @return {*}
 * @author: gumingchen
 */
function menuProcessing(list = [], mode = 1) {
  const result = [];
  list.forEach((item) => {
    if (item.type !== 2) {
      if (mode === 2 || item.show === 1) {
        const menu = {
          id: item.menu_id,
          name_cn: item.name_cn,
          name_en: item.name_en,
          icon: item.icon,
          type: item.type,
          url: item.url,
          path:
            item.type === 3
              ? `/i-${item.menu_id}`
              : item.path ||
                (item.url ? `/${item.url.replace(/\//g, "-")}` : ""),
          name:
            item.type === 3
              ? `i-${item.menu_id}`
              : item.name || (item.url ? item.url.replace(/\//g, "-") : ""),
          children: [],
        };
        if (item.children && item.children.length > 0) {
          menu.children = menuProcessing(item.children, mode);
        }
        result.push(menu);
      }
    }
  });
  return result;
}
const baseFrameworkHandle = (routerList) => {
  let data = routerList.filter((item) => {
    return item.path.startsWith("/module");
  });
  let linkData = routerList.find((item) => {
    return item.path == "/link";
  });

  if (linkData && linkData.children) {
    data.push(...linkData.children);
  }

  let baseFramework = routerList.filter((item) => {
    return item.path.startsWith("/baseFramework");
  });
  let adminData = [];
  if (baseFramework.length > 0) {
    adminData = baseFramework[0].children;
    if (adminData.length > 0) {
      let adminModuleData = [];
      adminData = adminData.filter((item) => {
        item.path = item.path.startsWith("/") ? item.path : "/" + item.path;
        if (item.path.startsWith("/module") && item.children) {
          // 特殊处理数据平台
          if(item.path.startsWith('/module/dataPlatform')){
            item.children.forEach((it) => {
              it.path = item.path + "/" + it.path;
            });
            adminModuleData.push(...item.children);
          }else{
            adminModuleData.push(item) ;
          }
        }
        return !item.path.startsWith("/module");
      });
      console.log("adminData", adminData);

      console.log("adminModuleData", adminModuleData);

      adminData.push(...adminModuleData);
    }
  }
  //将来删除
  // adminData.unshift(routerList[routerList.length - 1])
  data.push(...adminData);

  console.log("data", data);
  return data;
};

export default {
  state: {
    link: false,
    route: [],
    currentType: "main",
    currentOneLevel: "/index",
    get: getGet(),
    menus: [],
    rowMenus: [],
    permissions: [],
    active: getActiveMenu(),
    collapse: false,
    refresh: false,
  },
  getters: {
    menus: (state) => {
      return menuProcessing(state.menus);
    },
    pages: (state) => {
      return menuProcessing(state.menus, 2);
    },
    permissions: (state) => {
      return state.permissions;
    },
  },
  mutations: {
    SET_GET: (state, get) => {
      state.get = get;
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_ACTIVE: (state, active) => {
      state.active = active;
    },
    SET_COLLAPSE: (state, collapse) => {
      state.collapse = collapse;
    },
    currentOneLevelChange(state, name) {
      state.currentOneLevel = name;
    },
    setCurrentType(state, type) {
      state.currentType = type;
      //setMenuType(type)
    },
    SET_ROUTE(state, data) {
      state.route = data;
      state.rowMenus = JSON.parse(JSON.stringify(data));
    },
    setRefresh(state, refresh) {
      state.refresh = refresh;
    },
    setLink(state, link) {
      state.link = link;
    },
  },
  actions: {
    /**
     * 设置是否获取过 菜单 权限
     * @param {*}
     * @returns
     */
    setGet({ commit }, val = true) {
      setGet(val);
      commit("SET_GET", val);
    },
    /**
     * 获取当前管理员 菜单 权限
     * @param {*}
     * @returns
     */
    async getMenuAndPermission({ commit, dispatch }) {
      // const r = await selfInfoApi()
      // if (r) {
      //   dispatch('setGet', true)
      //   setMenuAndPermission(r.data)
      //   commit('SET_MENUS', r.data.menus)
      //   commit('SET_PERMISSIONS', r.data.permissions)
      // }
      // return r && r.data ? r.data.menus : []
    },
    /**
     * 设置选中菜单
     * @param {*}
     * @returns
     */
    setActive({ commit }, active) {
      console.log("setActive", active);
      commit("SET_ACTIVE", active);
      setActiveMenu(active);
    },
    /**
     * 设置菜单是否折叠
     * @param {*}
     * @returns
     */
    setCollapse({ commit }, collapse) {
      commit("SET_COLLAPSE", collapse);
    },
    /**
     * 清除菜单 权限 信息
     * @param {*}
     */
    clear({ commit }) {
      clearGet();
      clearMenuAndPermission();
      commit("SET_GET", false);
      commit("SET_MENUS", []);
      commit("SET_PERMISSIONS", []);
    },
    getMenu({ commit }) {
      return new Promise((resolve) => {
        // 向后端请求路由数据
        getRouters().then((res) => {
          let router = res.data.router;
          let copyRoute = JSON.parse(JSON.stringify(router));
          const data = baseFrameworkHandle(copyRoute);
          commit("SET_ROUTE", router);
          commit("SET_MENUS", data);
          if (data) {
            resolve(data);
          } else {
            resolve([]);
          }
        });
      });
    },
  },
};
