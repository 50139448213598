import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as serveInfoApi from '@/api/serveInfo';
import { download } from '@/utils/index';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import * as weChatApi from '@/api/weChatManage';
// import commonDrawer from './commonDrawer.vue'

export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 当前页签名称
      activeName: '待发通知',
      // 遮罩层
      loading: true,
      isExport: false,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 标志位-代表进入编辑后，是否更改了附件内容(图片/文件)
      chnageFlag: false,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 表单下拉关联参数
      formListParams: {
        citys: [],
        deptNames: [],
        courtsNames: []
      },
      // 表格选中的数据
      currentSelectRows: [],
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        infoType: null,
        number: null,
        releaseState: null,
        wechatGroupNames: [],
        startDatestr: '',
        endDatestr: '',
        originDate: []
      },
      cloneQueryParams: null,
      searchFormData: [{
        prop: "number",
        label: "信息编号",
        placeholder: "请输入信息编号查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "infoType",
        label: "信息类别",
        type: "list",
        placeholder: "请选择信息类别",
        span: 7,
        minWidth: 270,
        options: [{
          dictLabel: '临时停电',
          dictValue: '临时停电'
        }, {
          dictLabel: '故障停电',
          dictValue: '故障停电'
        }, {
          dictLabel: '计划停电',
          dictValue: '计划停电'
        }, {
          dictLabel: '复电信息',
          dictValue: '复电信息'
        }, {
          dictLabel: '抢修信息',
          dictValue: '抢修信息'
        }, {
          dictLabel: '其他信息',
          dictValue: '其他信息'
        }]
      }, {
        prop: "wechatGroupNames",
        label: "发布范围",
        minWidth: 270,
        type: "bigData_list_multi",
        placeholder: "请选择发布范围",
        multiple: true,
        span: 7,
        optionsUrl: "/wechat-group-manage/wechatGroup/getWechatGroupName",
        props: {
          label: 'wechatGroupName',
          value: 'wechatGroupName'
        },
        queryParams: {}
      }, {
        prop: "releaseState",
        label: "发布状态",
        minWidth: 270,
        type: "list",
        placeholder: "请输入发布状态",
        span: 25,
        options: [{
          dictLabel: '未发布',
          dictValue: '未发布'
        }, {
          dictLabel: '撤销发布',
          dictValue: '撤销发布'
        }],
        hideHandle: () => {
          return state.activeName !== '待发通知';
        }
      }, {
        prop: "originDate",
        label: "待发时间",
        type: "daterange",
        multiple: true,
        span: 7,
        change: val => {
          if (val?.length) {
            state.queryParams.startDatestr = val[0] + ' 00:00:00';
            state.queryParams.endDatestr = val[1] + ' 23:59:59';
          } else {
            state.queryParams.startDatestr = '';
            state.queryParams.endDatestr = '';
          }
        },
        hideHandle: () => {
          return state.activeName !== '待发通知';
        }
      }, {
        prop: "originDate",
        label: "发布时间",
        type: "daterange",
        multiple: true,
        span: 7,
        change: val => {
          if (val?.length) {
            state.queryParams.startDatestr = val[0] + ' 00:00:00';
            state.queryParams.endDatestr = val[1] + ' 23:59:59';
          } else {
            state.queryParams.startDatestr = '';
            state.queryParams.endDatestr = '';
          }
        },
        hideHandle: () => {
          return state.activeName === '待发通知';
        }
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: () => handleAdd()
      }, {
        buttonName: "编辑",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleEdit()
      }, {
        buttonName: "删除",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleDel()
      }, {
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "number",
        label: "信息编号"
      }, {
        prop: "infoType",
        label: "信息类别"
      }, {
        prop: "currentName",
        label: "发布人"
      }, {
        prop: "createDateStr",
        label: "创建时间"
      }, {
        prop: "wechatGroupName",
        label: "发布范围"
      }, {
        prop: "releaseState",
        label: "发布状态"
      }, {
        prop: "releaseDateStr",
        label: "待发时间"
      }, {
        prop: "operation",
        label: "操作",
        width: "250px",
        fixed: "right",
        operation: [{
          name: '消息查阅',
          clickFun: row => handleShow(row)
        }, {
          name: '立即发布',
          // disabledHandle:(row)=>{
          //   return row.releaseState===''
          // },
          clickFun: row => infoOperate(row, '发布')
        }, {
          name: '撤销发布',
          // disabledHandle:(row)=>{
          //   return row.releaseState==='撤销发布'
          // },
          clickFun: row => infoOperate(row, '撤销')
        }]
      }],
      operation: '新增',
      //是否为编辑操作
      transName: '',
      transformer: '',
      // 表单参数
      form: {
        infoType: '',
        wechatGroupName: '',
        time: '是',
        releaseDateStr: '',
        infoCategory: '文字',
        fileUrl: '',
        fileRaw: null,
        releaseContent: '',
        remark: '',
        deptNames: '',
        courtsManageNames: '',
        weChatAndRobot: []
      },
      cloneForm: null,
      // 表单校验
      rules: {
        feeder: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        wechatGroupName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        time: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        releaseDateStr: [{
          validator: (rule, value, callback) => {
            if (value) {
              if (new Date(value).getTime() <= new Date().getTime()) {
                callback(new Error('选择的时间不能小于当前时间！'));
              } else {
                callback();
              }
            } else {
              callback(new Error('不能为空'));
            }
          },
          trigger: "change"
        }],
        infoType: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        fileUrl: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        releaseContent: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }]
      },
      formData: [{
        prop: "infoType",
        label: "信息类别",
        placeholder: "请输入信息类别",
        options: [{
          dictLabel: '临时停电',
          dictValue: '临时停电'
        }, {
          dictLabel: '故障停电',
          dictValue: '故障停电'
        }, {
          dictLabel: '计划停电',
          dictValue: '计划停电'
        }, {
          dictLabel: '复电信息',
          dictValue: '复电信息'
        }, {
          dictLabel: '抢修信息',
          dictValue: '抢修信息'
        }, {
          dictLabel: '其他信息',
          dictValue: '其他信息'
        }],
        type: "list",
        isSelect: true,
        span: 24,
        disabledHandle(row) {
          return state.operation === '查阅';
        }
      }, {
        prop: "wechatGroupName",
        label: "发布范围",
        labelStr: "wechatGroupName",
        labelStr2: "deptNames",
        labelStr3: "courtsManageNames",
        placeholder: "请输入发布范围",
        type: "dialog-select-ser",
        showSelectInfoTip: '已选择微信群',
        isSelect: false,
        multiple: true,
        showSelectInfo: true,
        span: 24,
        dialogWidth: '80%',
        method: 'post',
        optionsUrl: '',
        disabledHandle(row) {
          return state.operation === '查阅';
        },
        selectLabel: (label, value, rows) => {
          const nameTemp = [];
          const deptTemp = [];
          const weChatAndRobot = [];
          if (rows?.length) {
            for (let item of rows) {
              nameTemp.push(item.courtsManageName);
              deptTemp.push(item.deptName);
              const row = {
                wechatGroupName: item.wechatGroupName,
                robotId: item.robotId,
                deptName: item.deptName
              };
              weChatAndRobot.push(row);
            }
          }
          state.form.weChatAndRobot = weChatAndRobot;
          state.form.courtsManageNames = nameTemp.join(',');
          state.form.deptNames = deptTemp.join(',');
        },
        query: {
          secondDept: '',
          city: '',
          deptName: ''
        },
        options: {
          localConfig: true,
          needNumberId: true,
          backFillData: {
            label: 'wechatGroupName',
            prop: 'wechatGroupName'
          },
          listDatas: [],
          searchFormData: []
        }
      }, {
        prop: "time",
        label: "是否定时发送",
        placeholder: "请选择是否定时发送",
        type: "list",
        props: {
          label: "label",
          value: "value"
        },
        isSelect: true,
        span: 24,
        options: [{
          value: '是',
          label: '是'
        }, {
          value: '否',
          label: '否'
        }],
        disabledHandle(row) {
          return state.operation === '查阅';
        },
        change: val => {
          state.form.releaseDateStr = null;
        }
      }, {
        prop: "releaseDateStr",
        label: "发布时间",
        placeholder: "请输入发布时间",
        type: "datetime",
        isSelect: false,
        span: 24,
        disabledHandle: () => {
          return state.operation === '查阅';
        },
        hideHandle: () => {
          return state.form.time === '否';
        }
      }, {
        prop: "infoCategory",
        label: "信息类型",
        placeholder: "请选择信息类型",
        type: "list",
        props: {
          label: "label",
          value: "value"
        },
        isSelect: true,
        span: 24,
        clearable: false,
        options: [{
          value: '文字',
          label: '文字'
        }, {
          value: '图片',
          label: '图片'
        }, {
          value: '附件',
          label: '附件'
        }],
        change: val => {
          state.rules = {
            feeder: [{
              required: true,
              message: "不能为空",
              trigger: "change"
            }],
            wechatGroupName: [{
              required: true,
              message: "不能为空",
              trigger: "change"
            }],
            time: [{
              required: true,
              message: "不能为空",
              trigger: "change"
            }],
            releaseDateStr: [{
              validator: (rule, value, callback) => {
                if (value) {
                  if (new Date(value).getTime() <= new Date().getTime()) {
                    callback(new Error('选择的时间不能小于当前时间！'));
                  } else {
                    callback();
                  }
                } else {
                  callback(new Error('不能为空'));
                }
              },
              trigger: "change"
            }],
            infoType: [{
              required: true,
              message: "不能为空",
              trigger: "change"
            }],
            fileUrl: [{
              required: true,
              message: "不能为空",
              trigger: "change"
            }]
          };
          if (val === '图片') {
            state.formData[5].tip = '图片上传支持png,jpg(查阅模式下，点击上传的文件名称，可下载)';
          } else if (val === '附件') {
            state.formData[5].tip = '文件上传支持doc,docx,pdf,xls,xlsx(查阅模式下，点击上传的文件名称，可下载)';
          } else {
            // 文字重新构建rules
            state.rules = {
              feeder: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }],
              wechatGroupName: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }],
              time: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }],
              releaseDateStr: [{
                validator: (rule, value, callback) => {
                  if (value) {
                    if (new Date(value).getTime() <= new Date().getTime()) {
                      callback(new Error('选择的时间不能小于当前时间！'));
                    } else {
                      callback();
                    }
                  } else {
                    callback(new Error('不能为空'));
                  }
                },
                trigger: "change"
              }],
              infoType: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }],
              fileUrl: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }],
              releaseContent: [{
                required: true,
                message: "不能为空",
                trigger: "change"
              }]
            };
          }
          if (state.operation === '编辑') {
            state.chnageFlag = true;
          }
          state.formData[5].fileList = [];
          state.form.fileUrl = '';
          state.form.fileRaw = null;
        },
        disabledHandle(row) {
          return state.operation === '查阅';
        }
      }, {
        prop: "fileUrl",
        label: "信息内容",
        placeholder: "请选择信息内容",
        type: "basic-upload",
        tip: '图片上传支持png,jpg(点击上传的文件名称，可下载)',
        fileList: [],
        autoUpload: false,
        drag: true,
        upLoadText: '可拖拽文件至此上传',
        span: 24,
        action: "",
        onChange: file => {
          if (state.operation === '编辑') {
            state.chnageFlag = true;
          }
          let allowFileType = [];
          if (state.form.infoCategory === '图片') {
            allowFileType = ['png', 'jpg', 'jpeg', 'svg', 'JPEG', 'JPG', 'PNG', 'SVG'];
          } else if (state.form.infoCategory === '文件') {
            allowFileType = ['xlsx', 'xls', 'pdf', 'docx', 'doc'];
          }
          const fileType = file.name.slice(file.name.lastIndexOf('.') + 1);
          if (!allowFileType.includes(fileType)) {
            ElMessage.closeAll();
            ElMessage.error('当前文件格式不正确');
            state.formData[5].fileList = [];
            state.form.fileUrl = '';
            state.form.fileRaw = null;
            return false;
          } else {
            // 占位
            state.form.fileUrl = '123';
            state.form.fileRaw = file.raw;
          }
        },
        onSuccess: (res, file) => {},
        handleRemove: file => {
          state.formData[5].fileList = [];
          state.form.fileUrl = '';
          state.form.fileRaw = null;
          if (state.operation === '编辑') {
            state.chnageFlag = true;
          }
        },
        hideHandle: () => {
          return state.form.infoCategory === '文字';
        },
        handlePreview: file => {
          if (state.operation === '查阅' || state.operation === '编辑') {
            serveInfoApi.downInfoFile({
              documentId: file.id,
              versionId: file.versionId
            }).then(res => {
              if (res.status === 200) {
                download(res.data, file.name);
              }
            });
          }
          return;
        },
        disabledHandle(row) {
          return state.operation === '查阅';
        }
      }, {
        prop: "releaseContent",
        label: "信息内容",
        placeholder: "请选择信息内容",
        type: "textarea",
        maxlength: 1000,
        span: 24,
        hideHandle: () => {
          return state.form.infoCategory !== '文字';
        },
        disabledHandle(row) {
          return state.operation === '查阅';
        }
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea",
        span: 24,
        disabledHandle(row) {
          return state.operation === '查阅';
        }
      }],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      dialogVisible: false,
      typeUrl: ''
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    state.cloneForm = JSON.parse(JSON.stringify(state.form));
    onMounted(() => {});
    const handleChange = function (val) {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.activeName = val;
      if (val === '待发通知') {
        state.queryParams.releaseState = null;
        state.tableHeader[7].label = '待发时间';
        state.tableHeader[8].operation = [{
          name: '消息查阅',
          clickFun: row => handleShow(row)
        }, {
          name: '立即发布',
          clickFun: row => infoOperate(row, '发布')
        }, {
          name: '撤销发布',
          clickFun: row => infoOperate(row, '撤销')
        }];
        state.buttonsList = [{
          buttonName: "新增",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "Plus",
          exp: () => handleAdd()
        }, {
          buttonName: "编辑",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleEdit()
        }, {
          buttonName: "删除",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleDel()
        }, {
          buttonName: "导出",
          buttonStyle: "primary",
          className: 'addBtn',
          exp: () => downloadExport()
        }];
      } else {
        state.queryParams.releaseState = '已发布';
        state.tableHeader[7].label = '已发时间';
        state.tableHeader[8].operation = [{
          name: '消息查阅',
          clickFun: row => handleShow(row)
        }];
        state.buttonsList = [{
          buttonName: "导出",
          buttonStyle: "primary",
          className: 'addBtn',
          exp: () => downloadExport()
        }];
      }
      resetQuery();
    };
    const handleSelectionChange = function (val) {
      state.currentSelectRows = val;
      if (val.length === 1) {
        state.buttonsList[1].disabled = false;
      } else {
        state.buttonsList[1].disabled = true;
      }
      if (val.length) {
        state.buttonsList[2].disabled = false;
      } else {
        state.buttonsList[2].disabled = true;
      }
    };
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      if (state.activeName !== '待发通知') {
        state.queryParams.releaseState = '已发布';
      }
      serveInfoApi.infomationList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.rows = res.data.records || [];
          state.list.total = res.data.total || 0;
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      serveInfoApi.informationExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '服务信息列表.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    const handleAdd = function () {
      state.operation = '新增';
      state.form = JSON.parse(JSON.stringify(state.cloneForm));
      state.dialogVisible = true;
      nextTick(() => {
        const formRef = unref(formContainer);
        setTimeout(() => {
          formRef.commonForm.clearValidate();
        }, 0);
      });
      state.title = "新增服务信息";
    };
    const handleEdit = function () {
      state.operation = '编辑';
      if (state.currentSelectRows[0].documentName) {
        state.formData[5].fileList = [{
          name: state.currentSelectRows[0].documentName,
          id: state.currentSelectRows[0].documentId,
          versionId: state.currentSelectRows[0].versionId
        }];
      }
      state.form = JSON.parse(JSON.stringify(state.currentSelectRows[0]));
      state.form.time = '是';
      state.dialogVisible = true;
      state.chnageFlag = false;
      state.title = "编辑服务信息";
    };
    const handleDel = function () {
      const ids = [];
      for (let item of state.currentSelectRows) {
        ids.push(item.id);
      }
      const formData = new FormData();
      formData.append('ids', ids);
      serveInfoApi.infoDel(formData).then(res => {
        if (res.code === 1000) {
          ElMessage.closeAll();
          ElMessage.success('删除成功');
          handleQuery();
        }
      });
    };
    const handleShow = function (row) {
      state.operation = '查阅';
      if (row.documentName) {
        state.formData[5].fileList = [{
          name: row.documentName,
          id: row.documentId,
          versionId: row.versionId
        }];
      }
      state.form = JSON.parse(JSON.stringify(row));
      if (new Date(row.createDateStr).getTime() == new Date(row.releaseDateStr).getTime()) {
        state.form.time = '否';
      } else {
        state.form.time = '是';
      }
      state.dialogVisible = true;
      nextTick(() => {
        const formRef = unref(formContainer);
        formRef.commonForm.clearValidate();
      });
      state.title = "查阅服务信息";
    };
    const infoOperate = function (row, operate) {
      if (operate === '发布') {
        serveInfoApi.informationPublish({
          id: row.id,
          releaseState: ''
        }).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('发布成功');
            resetQuery();
          } else {
            ElMessage.closeAll();
            ElMessage.success(res.message);
          }
        });
      } else {
        serveInfoApi.informationRevoke({
          id: row.id,
          releaseState: '撤销发布'
        }).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('撤销成功');
            resetQuery();
          } else {
            ElMessage.closeAll();
            ElMessage.success(res.message);
          }
        });
      }
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      handleQuery();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          const headers = {
            'Authorization': 'Bearer ' + getToken()
          };
          if (state.operation === '新增') {
            let formData = new FormData();
            if (state.form.infoCategory !== '文字') {
              formData.append('file', state.form.fileRaw, state.form.fileRaw.name);
            } else {
              formData.append('file', new Blob([{}], {
                type: "application/json"
              }), 'dontHaveFile.txt');
            }
            let temp = JSON.parse(JSON.stringify(state.form));
            if (sessionStorage.getItem('isAdmin') !== 'false') {
              temp = {
                ...temp,
                ...{
                  currentName: '',
                  currentDept: '',
                  isAdmin: '1',
                  currentISCCode: ''
                }
              };
            } else {
              temp = {
                ...temp,
                ...{
                  currentName: sessionStorage.getItem('currentName'),
                  currentDept: sessionStorage.getItem('currentDept'),
                  isAdmin: '',
                  currentISCCode: sessionStorage.getItem('currentISCCode')
                }
              };
            }
            const _temp = JSON.stringify(temp);
            formData.append('vo', new Blob([_temp], {
              type: "application/json"
            }));
            axios.post(process.env.VUE_APP_WE_CHAT_GROUP_MANAGE + `/information/create`, formData, {
              headers
            }).then(res => {
              if (res.data.code === 1000) {
                ElMessage.closeAll();
                ElMessage.success('新增成功');
                clearForm();
                resetQuery();
              } else {
                ElMessage.closeAll();
                ElMessage.warning(res.data.message);
              }
            });
          } else {
            let formData = new FormData();
            // 判断是否文件发生了变化，若发生变化需要重新写入文件对象传输给后台，否则不做变更
            if (state.chnageFlag && state.form.infoCategory !== '文字') {
              formData.append('file', state.form.fileRaw, state.form.fileRaw.name);
            } else {
              formData.append('file', new Blob([{}], {
                type: "application/json"
              }), 'dontHaveFile.txt');
            }
            let temp = JSON.parse(JSON.stringify(state.form));
            if (state.form.infoCategory !== '文字') {
              temp['changeFlag'] = state.chnageFlag ? 1 : 0;
            } else {
              temp['changeFlag'] = 0;
            }
            if (sessionStorage.getItem('isAdmin') !== 'false') {
              temp = {
                ...temp,
                ...{
                  currentName: '',
                  currentDept: '',
                  isAdmin: '1'
                }
              };
            } else {
              temp = {
                ...temp,
                ...{
                  currentName: sessionStorage.getItem('currentName'),
                  currentDept: sessionStorage.getItem('currentDept'),
                  isAdmin: ''
                }
              };
            }
            const _temp = JSON.stringify(temp);
            formData.append('vo', new Blob([_temp], {
              type: "application/json"
            }));
            axios.post(process.env.VUE_APP_WE_CHAT_GROUP_MANAGE + `/information/update`, formData, {
              headers
            }).then(res => {
              if (res.data.code === 1000) {
                ElMessage.closeAll();
                ElMessage.success('编辑成功');
                clearForm();
                resetQuery();
              } else {
                ElMessage.closeAll();
                ElMessage.warning(res.data.message);
              }
            });
          }
        }
      });
    };
    const clearForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.resetFields();
      state.dialogVisible = false;
      state.operation = '新增';
      state.formData[5].fileList = [];
    };
    // 查询单位下拉
    // const getDeptList = function(params={}){
    //   weChatApi.formDeptMuti(params).then(res=>{
    //    //console.log(state,'AAAAAAAAAAAAAAAABBBBBBBBBBBBBBBBCCCCCCCCCCCCCCCDDDDDDDDDDDDDDDDDDD')
    //     state.formData[1].options.searchFormData[2].options=res.data||[]
    //   })
    // }

    // const getCourtsManageList = function(params={}){
    //   weChatApi.formCourtsManageMuti(params).then(res=>{
    //     state.formData[1].options.searchFormData[4].options=res.data||[]
    //   })
    // }

    const __returned__ = {
      router,
      formContainer,
      state,
      handleChange,
      handleSelectionChange,
      getList,
      downloadExport,
      handleAdd,
      handleEdit,
      handleDel,
      handleShow,
      infoOperate,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get serveInfoApi() {
        return serveInfoApi;
      },
      get download() {
        return download;
      },
      get axios() {
        return axios;
      },
      get getToken() {
        return getToken;
      },
      get weChatApi() {
        return weChatApi;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};