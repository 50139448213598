import {weChatrequest} from '@/utils/request'

// 人员关系列表查询
export function personList(data) {
    return weChatrequest({
        url: '/personnelTopo/list',
        method: 'post',
        data
    })
}
export function personFirstList(data) {
    return weChatrequest({
        url: '/area/findPage',
        method: 'post',
        data
    })
}

// 人员关系列表导出
export function personExport(data) {
    return weChatrequest({
        url: '/personnelTopo/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}
export function personFirstExport(data) {
    return weChatrequest({
        url: '/area/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}
// 人员关系新增
export function personAdd(data) {
    return weChatrequest({
        url: '/personnelTopo/add',
        method: 'post',
        data
    })
}
export function personFirstAdd(data) {
    return weChatrequest({
        url: '/area/create',
        method: 'post',
        data
    })
}

// 人员关系编辑
export function personEdit(data) {
    return weChatrequest({
        url: '/personnelTopo/edit',
        method: 'post',
        data
    })
}
export function personFirstEdit(data) {
    return weChatrequest({
        url: '/area/update',
        method: 'post',
        data
    })
}
// 人员关系删除
export function personDel(data) {
    return weChatrequest({
        url: '/personnelTopo/deleteBatch',
        method: 'post',
        data
    })
}
export function personFirstDel(data) {
    return weChatrequest({
        url: '/area/delete',
        method: 'post',
        data
    })
}