import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-33ec43fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar-container flex-box flex_d-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SubItem = _resolveComponent("SubItem");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode(" <span class=\"collapse\">\r\n      <el-tooltip content=\"折叠/展开菜单\" placement=\"bottom\" :show-after=\"500\" :hide-after=\"0\" transition=\"\">\r\n        <el-icon class=\"iconfont icon-zhankai\" style=\"font-size: 22px; margin-right: 10px\"\r\n          :class=\"{ 'icon-shouqi2': !collapse }\" @click=\"collapseHandle\"></el-icon>\r\n      </el-tooltip>\r\n    </span> "), _createVNode(_component_el_scrollbar, {
    class: "flex-item_f-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_menu, {
      "default-active": _ctx.active,
      "background-color": _ctx.theme.backgroundColor !== '#fff' ? _ctx.theme.backgroundColor : '',
      "text-color": _ctx.theme.textColor,
      "active-text-color": _ctx.theme.activeTextColor,
      "unique-opened": true,
      collapse: _ctx.collapse
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, item => {
        return _openBlock(), _createBlock(_component_SubItem, {
          key: item.path,
          path: item.path,
          data: item
        }, null, 8 /* PROPS */, ["path", "data"]);
      }), 128 /* KEYED_FRAGMENT */))]),

      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["default-active", "background-color", "text-color", "active-text-color", "collapse"])]),
    _: 1 /* STABLE */
  })]);
}