import { computed, defineComponent, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SubItem from "./components/sub-item.vue";
export default defineComponent({
  components: {
    SubItem
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const theme = computed(() => store.state.theme.theme.menu);
    const menus = computed(() => store.state.common.menu);
    const active = computed(() => store.state.menu.active);
    const collapse = computed({
      get: () => store.state.menu.collapse,
      set: val => store.dispatch("menu/setCollapse", val)
    });

    // 路由处理
    let menuData = ref([]);
    let menu = JSON.parse(JSON.stringify(menus.value)).filter(item => {
      if (item.path == '/') {
        return item;
      }
    });
    menuData.value = menu[0].children;

    /**
     * @description: 路由变化事件
     * @param {*}
     * @return {*}
     * @author: gumingchen
     */
    const routeHandle = argRoute => {
      const name = argRoute.name;
      store.dispatch("menu/setActive", name);
    };
    watch(() => {
      return route.path;
    }, () => {
      routeHandle(route);
    }, {
      immediate: true
    });
    const collapseHandle = () => {
      collapse.value = !collapse.value;
      store.dispatch("common/toggleSideBar");
    };
    return {
      theme,
      active,
      collapse,
      menuData,
      collapseHandle
    };
  }
});