import {weChatrequest} from '@/utils/request'


export function selectCoverList(data) {
    return weChatrequest({
        url: '/courts/selectCoverListPage',
        method: 'post',
        data
    })
}

export function coverList(data) {
    return weChatrequest({
        url: '/courts/getCoverNum',
        method: 'post',
        data
    })
}


export function weChatStatisticsExport(data) {
    return weChatrequest({
        url: '/appealManage/total/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}