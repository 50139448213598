import { defineComponent, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "SubItem",
  props: {
    data: {
      type: Object,
      required: true
    },
    path: {
      type: String
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    // const data = computed(() => {
    //   if (props.data.children) {
    //     //详情页面不在菜单展示
    //     let filterChildren = props.data.children.filter(
    //       (item) => item.meta.menuType != "D"
    //     );
    //     return { ...props.data, children: filterChildren };
    //   } else {
    //     return props.data;
    //   }
    // });
    /**
     * 菜单点击事件
     * @param name 路由名称
     */
    const clickHandle = () => {
      router.push(props.path);
    };
    return {
      clickHandle,
      data: props.data
    };
  }
});