import {weChatrequest} from '@/utils/request'

// 服务信息列表查询
export function infomationList(data) {
    return weChatrequest({
        url: '/information/findPage',
        method: 'post',
        data
    })
}

// 服务信息列表列表导出
export function informationExport(data) {
    return weChatrequest({
        url: '/information/exportXls',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 服务信息列表新增
export function informationAdd(data) {
    return weChatrequest({
        url: '/information/create',
        method: 'post',
        data
    })
}

// 服务信息列表编辑
export function informationEdit(data) {
    return weChatrequest({
        url: '/information/update',
        method: 'post',
        data
    })
}

// 服务信息-立即发布
export function informationPublish(data) {
    return weChatrequest({
        url: '/information/publish',
        method: 'post',
        data
    })
}


// 服务信息-撤销
export function informationRevoke(data) {
    return weChatrequest({
        url: '/information/revoke',
        method: 'post',
        data
    })
}

// 服务信息发布-文件下载
export function downInfoFile(data) {
    return weChatrequest({
        url: '/information/downFiles',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 微信群删除
export function infoDel(data) {
    return weChatrequest({
        url: '/information/deleteByids',
        method: 'post',
        data
    })
}

