import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as setKeyWordApi from '@/api/setKeyWord';
import { download } from '@/utils/index';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import * as weChatApi from '@/api/weChatManage';
// import commonDrawer from './commonDrawer.vue'

export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 当前页签名称
      activeName: '词条种类',
      // 遮罩层
      loading: true,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      // 标志位-代表进入编辑后，是否更改了附件内容(图片/文件)
      chnageFlag: false,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 表单下拉关联参数
      formListParams: {
        categoryNames: ''
      },
      // 表格选中的数据
      currentSelectRows: [],
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        categoryName: '',
        number: null,
        sort: 1,
        entryName: '',
        startDatestr: '',
        endDatestr: '',
        originDate: [],
        categoryNames: ''
      },
      cloneQueryParams: null,
      searchFormData: [{
        prop: "categoryName",
        label: "种类名称",
        placeholder: "请输入种类名称查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px',
        hideHandle: () => {
          return state.activeName !== '词条种类';
        }
      }, {
        prop: "entryName",
        label: "词条内容",
        placeholder: "请输入词条内容查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px',
        hideHandle: () => {
          return state.activeName !== '词条内容';
        }
      }, {
        prop: "categoryNames",
        label: "所属种类",
        type: "list",
        placeholder: "请选择所属种类",
        span: 24,
        options: [],
        props: {
          label: 'entryType',
          value: 'entryType'
        },
        queryParams: {},
        hideHandle: () => {
          return state.activeName == '词条种类';
        }
      }, {
        prop: "originDate",
        label: "创建时间",
        type: "daterange",
        multiple: true,
        span: 7,
        change: val => {
          if (val?.length) {
            state.queryParams.startDatestr = val[0] + ' 00:00:00';
            state.queryParams.endDatestr = val[1] + ' 23:59:59';
          } else {
            state.queryParams.startDatestr = '';
            state.queryParams.endDatestr = '';
          }
        },
        hideHandle: () => {
          return false;
        }
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: () => handleAdd()
      }, {
        buttonName: "编辑",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleEdit()
      }, {
        buttonName: "删除",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "",
        disabled: true,
        exp: () => handleDel()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        isShow: false,
        prop: "entryName",
        label: "词条内容"
      }, {
        prop: "categoryName",
        label: "种类名称"
      }, {
        prop: "sort",
        label: "排序"
      }, {
        prop: "createBy",
        label: "创建者"
      }, {
        prop: "createDate",
        label: "创建时间"
      }, {
        prop: "updateBy",
        label: "修改者"
      }, {
        prop: "updateDate",
        label: "修改时间"
      }],
      operation: '新增',
      //是否为编辑操作
      transName: '',
      transformer: '',
      // 表单参数
      form: {
        categoryName: '',
        sort: 1,
        entryName: '',
        categoryNames: ''
      },
      cloneForm: null,
      // 表单校验
      rules: {
        categoryName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        categoryNames: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        entryName: [{
          required: true,
          message: "不能为空",
          trigger: "change"
        }],
        sort: [{
          validator: (rule, value, callback) => {
            if (value) {
              if (!value.toString().trim().match(/^[\d.]*$/)) {
                callback(new Error('只能输入数字！'));
              } else {
                callback();
              }
            } else {
              callback(new Error('不能为空'));
            }
          },
          trigger: "change"
        }]
      },
      formData: [{
        prop: "categoryName",
        label: "种类名称",
        placeholder: "请输入种类名称",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }, {
        prop: "sort",
        label: "排序",
        placeholder: "请输入排序序号",
        type: "text",
        isSelect: false,
        span: 24,
        minWidth: '328px'
      }],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      dialogVisible: false,
      typeUrl: '',
      isExport: ''
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    state.cloneForm = JSON.parse(JSON.stringify(state.form));
    onMounted(() => {});
    const getCategorys = function (flag) {
      setKeyWordApi.getCategorysList().then(res => {
        if (flag == 1) {
          state.searchFormData[2].options = res.data || [];
        } else {
          state.formData[0].options = res.data || [];
        }
      });
    };
    const handleChange = function (val) {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      state.activeName = val;
      if (val === '词条种类') {
        state.tableHeader[1].isShow = false;
        state.buttonsList = [{
          buttonName: "新增",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "Plus",
          exp: () => handleAdd()
        }, {
          buttonName: "编辑",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleEdit()
        }, {
          buttonName: "删除",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleDel()
        }];
      } else {
        state.tableHeader[1].isShow = true;
        state.buttonsList = [{
          buttonName: "新增",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "Plus",
          exp: () => handleAdd()
        }, {
          buttonName: "编辑",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleEdit()
        }, {
          buttonName: "删除",
          buttonStyle: "primary",
          className: 'addBtn',
          buttonIcon: "",
          disabled: true,
          exp: () => handleDel()
        }, {
          buttonName: "导出",
          buttonStyle: "primary",
          className: 'addBtn',
          exp: () => downloadExport()
        }];
        getCategorys(1);
      }
      resetQuery();
    };
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      setKeyWordApi.keyWordExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '舆情关键词.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    const handleSelectionChange = function (val) {
      state.currentSelectRows = val;
      if (val.length === 1) {
        state.buttonsList[1].disabled = false;
      } else {
        state.buttonsList[1].disabled = true;
      }
      if (val.length) {
        state.buttonsList[2].disabled = false;
      } else {
        state.buttonsList[2].disabled = true;
      }
    };
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      if (state.activeName == '词条种类') {
        setKeyWordApi.entryCategoryList(state.queryParams).then(res => {
          if (res.code === 1000) {
            state.list.rows = res.data.rows || [];
            state.list.total = res.data.total || 0;
            for (let i in state.list.rows) {
              state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            }
          }
        }).finally(() => {
          state.loading = false;
        });
      } else {
        setKeyWordApi.entryContentList(state.queryParams).then(res => {
          if (res.code === 1000) {
            state.list.rows = res.data.rows || [];
            state.list.total = res.data.total || 0;
            for (let i in state.list.rows) {
              state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            }
          }
        }).finally(() => {
          state.loading = false;
        });
      }
    };
    const handleAdd = function () {
      state.operation = '新增';
      state.form = JSON.parse(JSON.stringify(state.cloneForm));
      state.dialogVisible = true;
      nextTick(() => {
        const formRef = unref(formContainer);
        setTimeout(() => {
          formRef.commonForm.clearValidate();
        }, 0);
      });
      if (state.activeName == '词条种类') {
        state.title = "新增词条种类";
        state.formData = [{
          prop: "categoryName",
          label: "种类名称",
          placeholder: "请输入种类名称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "sort",
          label: "排序",
          placeholder: "请输入排序序号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        state.form.categoryName = '';
        state.form.sort = 1;
      } else {
        state.title = "新增词条内容";
        state.formData = [{
          prop: "categoryNames",
          label: "所属种类",
          type: "list",
          placeholder: "请选择所属种类",
          span: 24,
          options: [],
          props: {
            label: 'entryType',
            value: 'entryType'
          },
          queryParams: {},
          hideHandle: () => {
            return state.activeName == '词条种类';
          }
        }, {
          prop: "entryName",
          label: "词条内容",
          placeholder: "请输入词条内容",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "sort",
          label: "排序",
          placeholder: "请输入排序序号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        getCategorys(2);
        state.form.categoryNames = '';
        state.form.entryName = '';
        state.form.sort = null;
      }
    };
    const handleEdit = function () {
      state.operation = '编辑';
      state.dialogVisible = true;
      state.chnageFlag = false;
      state.form = JSON.parse(JSON.stringify(state.currentSelectRows[0]));
      if (state.activeName == '词条种类') {
        state.title = "编辑词条种类";
        state.formData = [{
          prop: "categoryName",
          label: "种类名称",
          placeholder: "请输入种类名称",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "sort",
          label: "排序",
          placeholder: "请输入排序序号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        state.form.categoryName = '';
        state.form.sort = 1;
      } else {
        state.title = "编辑词条内容";
        state.formData = [{
          prop: "categoryNames",
          label: "所属种类",
          type: "list",
          placeholder: "请选择所属种类",
          span: 24,
          options: [],
          props: {
            label: 'entryType',
            value: 'entryType'
          },
          queryParams: {},
          hideHandle: () => {
            return state.activeName == '词条种类';
          }
        }, {
          prop: "entryName",
          label: "词条内容",
          placeholder: "请输入词条内容",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }, {
          prop: "sort",
          label: "排序",
          placeholder: "请输入排序序号",
          type: "text",
          isSelect: false,
          span: 24,
          minWidth: '328px'
        }];
        getCategorys(2);
        state.form.categoryNames = state.currentSelectRows[0].categoryName;
      }
    };
    const handleDel = function () {
      const ids = [];
      for (let item of state.currentSelectRows) {
        ids.push(item.id);
      }
      if (state.activeName == '词条种类') {
        setKeyWordApi.entryCategoryDel(ids).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('删除成功');
            handleQuery();
          }
        });
      } else {
        setKeyWordApi.entryContentDel(ids).then(res => {
          if (res.code === 1000) {
            ElMessage.closeAll();
            ElMessage.success('删除成功');
            handleQuery();
          }
        });
      }
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      handleQuery();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          if (state.operation === '新增') {
            if (state.activeName == '词条种类') {
              setKeyWordApi.entryCategoryAdd(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('新增成功');
                  clearForm();
                  handleQuery();
                }
              });
            } else {
              setKeyWordApi.entryContentAdd(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('新增成功');
                  clearForm();
                  handleQuery();
                }
              });
            }
          } else {
            if (state.activeName == '词条种类') {
              setKeyWordApi.entryCategoryEdit(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('编辑成功');
                  clearForm();
                  handleQuery();
                }
              });
            } else {
              setKeyWordApi.entryContentEdit(state.form).then(res => {
                if (res.code === 1000) {
                  ElMessage.closeAll();
                  ElMessage.success('编辑成功');
                  clearForm();
                  handleQuery();
                }
              });
            }
          }
        }
      });
    };
    const clearForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.resetFields();
      state.dialogVisible = false;
      state.operation = '新增';
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      getCategorys,
      handleChange,
      downloadExport,
      handleSelectionChange,
      getList,
      handleAdd,
      handleEdit,
      handleDel,
      handleQuery,
      resetQuery,
      submitForm,
      clearForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get setKeyWordApi() {
        return setKeyWordApi;
      },
      get download() {
        return download;
      },
      get axios() {
        return axios;
      },
      get getToken() {
        return getToken;
      },
      get weChatApi() {
        return weChatApi;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};