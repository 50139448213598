import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import commonPieChart from './commonPieChart.vue';
import * as weChatGroupCoverCourtApi from '@/api/weChatGroupCoverCourt';
import * as weChatApi from '@/api/weChatManage';
import { download } from '@/utils/index';
import { Decrypt } from '@/utils/cryptojs';
import { get } from "sortablejs";
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 遮罩层
      loading: false,
      // 16900 U-功能测试-配电房监视-配电房管理-编辑，多次点击确定按钮，重复发送请求，添加多个配电房数据 byeyunli 2023/4/23
      saveLoading: false,
      wetotal: 0,
      // 选中数组
      ids: [],
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        secondDept: '',
        city: '',
        fourthDept: '',
        deptName: '',
        lineName: '',
        lineId: '',
        courtsName: '',
        courtsId: '',
        wechatGroupNameOriginal: '',
        coverState: '',
        courtType: '公变'
      },
      roleAdmin: false,
      rolejzbl: false,
      searchFormData: [{
        prop: "secondDept",
        label: "所属地市",
        type: "bigData_list_multi",
        placeholder: "请选择所属地市",
        multiple: false,
        disabledHandle(row) {
          return !state.roleAdmin; //可操作，其余禁用
        },

        // clearable:'no',
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'secondDept',
          value: 'secondDept'
        },
        change: val => {
          let queryParams = {
            secondDept: val
          };
          state.queryParams.city = '';
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getCityList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "city",
        label: "所属区域",
        // clearable:'no',
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        type: "bigData_list_multi",
        placeholder: "请选择所属区域",
        multiple: false,
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'city',
          value: 'city'
        },
        change: val => {
          let queryParams = {
            city: val,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.deptName = '';
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getDeptList1(queryParams);
        },
        queryParams: {}
      }, {
        prop: "deptName",
        label: "所属单位",
        disabledHandle(row) {
          return !state.rolejzbl; //admin和省市集中办理可操作，其余禁用
        },

        // clearable:'no',
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属单位",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'deptName',
          value: 'deptName'
        },
        change: val => {
          let queryParams = {
            deptName: val,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.fourthDept = '';
          state.queryParams.courtsManageNames = [];
          getFourtList(queryParams);
          getLinesList(queryParams);
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "fourthDept",
        label: "所属班所",
        multiple: false,
        type: "bigData_list_multi",
        placeholder: "请选择所属班所",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'fourthDept',
          value: 'fourthDept'
        },
        change: val => {
          let queryParams = {
            fourthDept: val,
            deptName: state.queryParams.deptName,
            city: state.queryParams.city,
            secondDept: state.queryParams.secondDept
          };
          state.queryParams.lineName = '';
          state.queryParams.lineId = '';
          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getLinesList(queryParams);
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "lineName",
        label: "所属线路",
        type: "bigData_list_multi",
        placeholder: "请输入所属线路",
        span: 7,
        options: [],
        minWidth: 270,
        props: {
          label: 'lineName',
          value: 'lineId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.lineId === val);
          if (selectedOption) {
            state.queryParams.lineId = val;
            state.queryParams.lineName = selectedOption.lineName;
          }
          let queryParams = {
            deptName: state.queryParams.deptName,
            fourthDept: state.queryParams.fourthDept,
            // city:state.queryParams.city,
            // secondDept:state.queryParams.secondDept,
            lineName: state.queryParams.lineName
            // lineId:val
          };

          state.queryParams.courtsName = '';
          state.queryParams.courtsManageNames = [];
          getCourtsList(queryParams);
        },
        queryParams: {}
      }, {
        prop: "courtsName",
        label: "台区名称",
        type: "bigData_list_multi",
        placeholder: "请输入台区名称",
        multiple: false,
        span: 7,
        minWidth: 270,
        options: [],
        props: {
          label: 'courtsName',
          value: 'courtsId'
        },
        change: (val, control) => {
          const selectedOption = control.find(option => option.courtsId === val);
          if (selectedOption) {
            state.queryParams.courtsId = val;
            state.queryParams.courtsName = selectedOption.courtsName;
          }
          //   let queryParams={
          //     deptName:state.queryParams.deptName,
          //     fourthDept:state.queryParams.fourthDept,
          //     // city:state.queryParams.city,
          //     // secondDept:state.queryParams.secondDept,
          //     lineName:state.queryParams.lineName,
          //     // lineId:state.queryParams.lineId,
          //     courtsName:selectedOption.courtsName,
          //     // courtsId:val
          //    }
          //    state.queryParams.courtsManageNames=[]
        },

        queryParams: {}
      }, {
        prop: "courtType",
        label: "台区类型",
        type: "list",
        clearable: false,
        placeholder: "请选择台区类型",
        span: 7,
        minWidth: 200,
        change: (val, control) => {
          handleQuery();
        },
        options: [{
          dictLabel: '公变',
          dictValue: '公变'
        }, {
          dictLabel: '专变',
          dictValue: '专变'
        }]
      }, {
        prop: "coverState",
        label: "覆盖状态",
        type: "list",
        placeholder: "请选择覆盖状态",
        span: 7,
        minWidth: 200,
        options: [{
          dictLabel: '已覆盖',
          dictValue: '已覆盖'
        }, {
          dictLabel: '未覆盖',
          dictValue: '未覆盖'
        }]
      }, {
        prop: "wechatGroupNameOriginal",
        label: "微信群名称",
        placeholder: "请输入微信群名称查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '200px'
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "deptName",
        label: "所属单位"
      }, {
        prop: "lineName",
        label: "所属线路"
      }, {
        prop: "courtsName",
        label: "台区名称"
      }, {
        prop: "courtType",
        label: "台区类型"
      }, {
        prop: "wechatGroupNameOriginal",
        label: "微信群名称"
      }, {
        prop: "coverState",
        label: "覆盖状态"
      }, {
        prop: "courtsManageName",
        label: "台区经理"
      }, {
        prop: "courtsManagePhone",
        label: "联系电话"
      }, {
        prop: "groupUserNum",
        label: "群成员数量"
      }],
      pieData: {
        legend: [],
        series: [],
        allCount: 0
      },
      columnData: {
        data: [],
        series: []
      }
    });
    onMounted(() => {
      firstList();
    });
    const firstList = function () {
      if (sessionStorage.getItem('isAdmin') == "false") {
        state.roleAdmin = false;
        let roleInfo = sessionStorage.getItem('roleInfo');
        let currentDept = sessionStorage.getItem('currentDept');
        // wgwxbsz班所  wgwxkhjl客户经理  wgwxfwzz专责 --currentDept--接口 根据单位反查currentDept
        // wgwxjzbl省市集中办理--currentDept--市
        if (roleInfo == "wgwxkhjl" || roleInfo == "wgwxfwzz" || roleInfo == "wgwxbsz") {
          state.rolejzbl = false;
          state.queryParams.deptName = currentDept;
          weChatApi.formSecondDept({
            currentDept: currentDept
          }).then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            let queryParams = {
              secondDept: res.data[0].secondDept,
              currentDept: currentDept
            };
            weChatApi.formCity(queryParams).then(res => {
              state.searchFormData[1].options = res.data || [];
              state.queryParams.city = res.data[0].city;
              let queryParams = {
                deptName: state.queryParams.deptName,
                city: state.queryParams.city,
                secondDept: state.queryParams.secondDept
              };
              weChatGroupCoverCourtList();
              getFourtList(queryParams);
              getLinesList(queryParams);
              getCourtsList(queryParams);
              getList();
            });
          });
        }
        if (roleInfo == "wgwxjzbl") {
          state.rolejzbl = true;
          weChatApi.formSecondDept().then(res => {
            state.searchFormData[0].options = res.data || [];
            state.queryParams.secondDept = res.data[0].secondDept;
            getCityList1({
              secondDept: res.data[0].secondDept
            });
            weChatGroupCoverCourtList();
            getList();
          });
        }
      } else {
        state.roleAdmin = true;
        state.rolejzbl = true;
        getSecondDeptList();
        weChatGroupCoverCourtList();
        getList();
      }
    };
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      weChatGroupCoverCourtApi.selectCoverList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.rows = res.data.records || [];
          state.list.total = res.data.total || 0;
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            let courtsManageName = state.list.rows[i]['courtsManageName'];
            if (courtsManageName != null && courtsManageName != '') {
              state.list.rows[i]['courtsManageName'] = Decrypt(courtsManageName);
            }
            let courtsManagePhone = state.list.rows[i]['courtsManagePhone'];
            if (courtsManagePhone != null && courtsManagePhone != '') {
              state.list.rows[i]['courtsManagePhone'] = Decrypt(courtsManagePhone);
            }
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };

    // 查询机器人管理群信息
    const weChatGroupCoverCourtList = function () {
      let params = JSON.parse(JSON.stringify(state.queryParams));
      delete params.pageNum;
      delete params.pageSize;
      weChatGroupCoverCourtApi.coverList(params).then(res => {
        if (res.code === 1000) {
          state.pieData.legend = res.data.legend || [];
          state.pieData.series = res.data.series || [];
          state.pieData.allCount = res.data.wechatNums || 0;
          state.wetotal = res.data.byCourtTypeNum || 0;
        }
      });
    };
    const getSecondDeptList = function (params = {}) {
      weChatApi.formSecondDept(params).then(res => {
        state.searchFormData[0].options = res.data || [];
        // if(!state.queryParams.secondDept){
        //   state.queryParams.secondDept=res.data[0].secondDept
        //   getCityList1({secondDept:state.queryParams.secondDept});
        // }
      });
    };

    const getCityList1 = function (params = {}) {
      weChatApi.formCity(params).then(res => {
        state.searchFormData[1].options = res.data || [];
        // if(!state.queryParams.city){
        //   state.queryParams.city=res.data[0].city
        //   getDeptList1({city:state.queryParams.city});
        // }
      });
    };

    const getDeptList1 = function (params = {}) {
      weChatApi.formDept(params).then(res => {
        state.searchFormData[2].options = res.data || [];
        // if(!state.queryParams.deptName){
        //   state.queryParams.deptName=res.data[0].deptName
        //   let queryParams={
        //       deptName:state.queryParams.deptName,
        //       city:state.queryParams.city,
        //       secondDept:state.queryParams.secondDept
        //   }
        //   getList(state.queryParams)
        //   getFourtList(queryParams)
        //   getLinesList(queryParams)
        //   getCourtsList(queryParams)
        // }
      });
    };

    const getFourtList = function (params = {}) {
      weChatApi.formFourth(params).then(res => {
        state.searchFormData[3].options = res.data || [];
      });
    };
    const getLinesList = function (params = {}) {
      weChatApi.formLines(params).then(res => {
        state.searchFormData[4].options = res.data || [];
      });
    };
    // 查询台区下拉
    const getCourtsList = function (params = {}) {
      weChatApi.formCourts(params).then(res => {
        // if(res.data instanceof Array){
        //     for(let item of res.data){
        //       if(item.courtsManageName!=null && item.courtsManageName!=''){
        //         item.courtsManageName=Decrypt(item.courtsManageName)
        //       }
        //       if(item.courtsManagePhone!=null && item.courtsManagePhone!=''){
        //         item.courtsManagePhone=Decrypt(item.courtsManagePhone)
        //       }
        //     }
        // }
        // state.formData[4].options=res.data||[]
        // state.formData[5].options=res.data||[]
        // state.formData[6].options=res.data||[]
        state.searchFormData[5].options = res.data || [];
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
      weChatGroupCoverCourtList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams.secondDept = '';
      state.queryParams.city = '';
      state.queryParams.deptName = '';
      state.queryParams.lineName = '';
      state.queryParams.lineId = '';
      state.queryParams.courtsName = '';
      state.queryParams.courtsId = '';
      state.queryParams.wechatGroupNameOriginal = '';
      state.queryParams.coverState = '';
      state.queryParams.courtType = '公变';
      state.queryParams.fourthDept = '';
      firstList();
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      firstList,
      getList,
      weChatGroupCoverCourtList,
      getSecondDeptList,
      getCityList1,
      getDeptList1,
      getFourtList,
      getLinesList,
      getCourtsList,
      handleQuery,
      resetQuery,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      commonPieChart,
      get weChatGroupCoverCourtApi() {
        return weChatGroupCoverCourtApi;
      },
      get weChatApi() {
        return weChatApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      },
      get get() {
        return get;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};