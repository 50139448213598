import * as echarts from "echarts";
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {},
  name: '',
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "100%"
    },
    chartsData: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: '总数'
    },
    unit: {
      type: String,
      default: ''
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    yName: {
      type: String,
      default: ''
    },
    xName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    let myEcharts = ref(null);
    let myChart = null; //要用普通变量来接收 echarts 实例,否则echarts图 tooltip 不显示
    const state = reactive({
      option: null,
      tooltioBackgroundColor: "#0A4C5D",
      titleColor: "#fff"
    });
    const setOptions = val => {
      if (props.isColumn) {
        state.option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (val) {
              return `${val[0].name}${props.name}：${val[0].value}${props.unit}`;
            }
          },
          //控制直角坐标系与容器上下左右的距离
          grid: {
            left: '5%',
            right: '12%',
            bottom: '5%',
            top: '20%',
            containLabel: true //gid区域是否包含坐标轴的刻度标签。
          },

          // x轴（指的是水平的那条线）相关配置
          xAxis: [{
            type: 'value',
            //x轴数据类型，value时就是值，category时就是分类，可用于区分y轴与x轴
            name: props.xName,
            axisLabel: {
              // 坐标轴标签
              show: true,
              // 是否显示
              // inside: true, // 标签是否朝内,默认false
              interval: 0,
              //设置刻度间间隔，0表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
              rotate: 20,
              // 旋转角度
              margin: 5,
              // 刻度标签与轴线之间的距离
              color: state.titleColor,
              // 标签颜色默认取轴线的颜色 
              fontSize: 12 //标签字号
            },

            splitLine: {
              // 网格线
              show: false // 是否显示，默认为true
            },

            axisLine: {
              // 坐标轴 轴线
              show: true,
              // 是否显示,默认false
              lineStyle: {
                color: state.titleColor,
                //轴线颜色
                width: 1,
                //轴线宽度
                type: 'solid' //轴线类型
              }
            }
          }],

          // y轴（垂直的那条线）设置
          yAxis: [{
            type: 'category',
            data: val.data,
            //类目轴数据
            name: props.yName,
            axisLabel: {
              // 坐标轴标签
              show: true,
              // 是否显示
              // inside: true, // 标签是否朝内,默认false
              interval: 0,
              //设置刻度间间隔，0表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
              rotate: 0,
              // 旋转角度
              margin: 5,
              // 刻度标签与轴线之间的距离
              color: state.titleColor,
              // 标签颜色默认取轴线的颜色 
              fontSize: 12 //标签字号
            },

            splitLine: {
              // 网格线
              show: false // 是否显示，默认为true
            },

            axisLine: {
              // 坐标轴 轴线
              show: true,
              // 是否显示,默认false
              lineStyle: {
                color: state.titleColor,
                //轴线颜色
                width: 1,
                //轴线宽度
                type: 'solid' //轴线类型
              }
            }
          }],

          series: [{
            name: props.name,
            type: 'bar',
            //表明柱状体，饼图是pie，折线图是line
            barWidth: 10,
            //柱子宽度
            data: val.series,
            //值数据，与类型数据一一对应
            itemStyle: {
              // emphasis: {
              //     barBorderRadius: 7,
              // },
              normal: {
                //柱子相关设置
                label: {
                  //柱子标签相关
                  show: true,
                  position: 'right',
                  //柱子标签位置
                  textStyle: {
                    //柱子标签文本设置
                    color: state.titleColor,
                    fontSize: 12
                  }
                },
                color: 'skyblue'
              }
            }
          }]
        };
      } else {
        state.option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (val) {
              return `${val[0].name}${props.name}：${val[0].value}${props.unit}`;
            }
          },
          //控制直角坐标系与容器上下左右的距离
          grid: {
            left: '5%',
            right: '12%',
            bottom: '5%',
            top: '20%',
            containLabel: true //gid区域是否包含坐标轴的刻度标签。
          },

          // x轴（指的是水平的那条线）相关配置
          xAxis: [{
            type: 'category',
            data: val.data,
            //类目轴数据
            name: props.xName,
            axisLabel: {
              // 坐标轴标签
              show: true,
              // 是否显示
              // inside: true, // 标签是否朝内,默认false
              interval: 0,
              //设置刻度间间隔，0表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
              rotate: 20,
              // 旋转角度
              margin: 5,
              // 刻度标签与轴线之间的距离
              color: state.titleColor,
              // 标签颜色默认取轴线的颜色 
              fontSize: 12 //标签字号
            },

            splitLine: {
              // 网格线
              show: false // 是否显示，默认为true
            },

            axisLine: {
              // 坐标轴 轴线
              show: true,
              // 是否显示,默认false
              lineStyle: {
                color: state.titleColor,
                //轴线颜色
                width: 1,
                //轴线宽度
                type: 'solid' //轴线类型
              }
            }
          }],

          // y轴（垂直的那条线）设置
          yAxis: [{
            type: 'value',
            //x轴数据类型，value时就是值，category时就是分类，可用于区分y轴与x轴
            name: props.yName,
            axisLabel: {
              // 坐标轴标签
              show: true,
              // 是否显示
              // inside: true, // 标签是否朝内,默认false
              interval: 0,
              //设置刻度间间隔，0表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
              rotate: 0,
              // 旋转角度
              margin: 5,
              // 刻度标签与轴线之间的距离
              color: state.titleColor,
              // 标签颜色默认取轴线的颜色 
              fontSize: 12 //标签字号
            },

            splitLine: {
              // 网格线
              show: false // 是否显示，默认为true
            },

            axisLine: {
              // 坐标轴 轴线
              show: true,
              // 是否显示,默认false
              lineStyle: {
                color: state.titleColor,
                //轴线颜色
                width: 1,
                //轴线宽度
                type: 'solid' //轴线类型
              }
            }
          }],

          series: [{
            name: props.name,
            type: 'bar',
            //表明柱状体，饼图是pie，折线图是line
            barWidth: 10,
            //柱子宽度
            data: val.series,
            //值数据，与类型数据一一对应
            itemStyle: {
              // emphasis: {
              //     barBorderRadius: 7,
              // },
              normal: {
                //柱子相关设置
                label: {
                  //柱子标签相关
                  show: true,
                  position: 'top',
                  //柱子标签位置
                  textStyle: {
                    //柱子标签文本设置
                    color: state.titleColor,
                    fontSize: 12
                  }
                },
                color: 'skyblue'
              }
            }
          }]
        };
      }
      myChart.setOption(state.option);
    };
    // 字体自适应。
    const fontSizeChange = val => {
      let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return val * fontSize;
    };
    onMounted(() => {
      state.tooltioBackgroundColor = "rgba(50,50,50,0.7)";
      state.titleColor = "#000";
      nextTick(() => {
        myChart = echarts.init(myEcharts.value);
        setOptions(props.chartsData);
      });
    });
    onBeforeUnmount(() => {
      if (myChart) {
        myChart.dispose(); //销毁
      }
    });
    //数据监听
    watch(() => props.chartsData, () => {
      if (myChart) {
        myChart.clear();
      }
      setOptions(props.chartsData);
    }, {
      deep: true
    });
    //窗口监听渲染
    window.addEventListener('resize', () => {
      setOptions(props.chartsData);
      myChart.resize();
    });
    return {
      ...toRefs(state),
      setOptions,
      myEcharts,
      fontSizeChange
    };
  }
});