import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src", "v-show"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("iframe", {
    width: "100%",
    src: _ctx.iframeUrl,
    "v-show": _ctx.isVisible,
    frameborder: "0",
    scrolling: "no",
    style: {
      "min-height": "80vh"
    }
  }, null, 8 /* PROPS */, _hoisted_1)]);
}