import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formValidate } from "@/utils/validate";
import * as robotApi from '@/api/robotManage';
import { download } from '@/utils/index';
import { Decrypt } from '@/utils/cryptojs';
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const router = useRouter();
    const formContainer = ref(null);
    const state = reactive({
      // 聊天记录列表
      infoList: [],
      infoListToTal: 0,
      infoListLoading: false,
      isExport: false,
      groupBasicInfo: {
        unit: '',
        person: '',
        phone: '',
        groupName: ''
      },
      // 聊天记录查询条件
      infoListQueryParams: {
        pageNum: 1,
        pageSize: 20,
        wechatGroupName: ''
      },
      // 遮罩层
      loading: true,
      listLoading: false,
      saveLoading: false,
      // 模型表格数据
      list: {
        total: 0,
        rows: []
      },
      manageList: {
        total: 0,
        rows: []
      },
      // 查询参数
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        robotName: null,
        robotId: null,
        phone: null,
        state: null
      },
      cloneQueryParams: null,
      listQueryParams: {
        pageSize: 10,
        pageNum: 1,
        wechatGroupName: '',
        robotId: ''
      },
      dialogVisible: false,
      groupTalkHistoryDialogVisible: false,
      searchFormData: [{
        prop: "robotName",
        label: "机器人昵称",
        placeholder: "请输入机器人昵称查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "robotId",
        label: "机器人编号",
        placeholder: "请输入机器人编号查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "phone",
        label: "绑定手机号",
        placeholder: "请输入绑定手机号查询",
        type: "text",
        isSelect: false,
        span: 7,
        minWidth: '328px'
      }, {
        prop: "state",
        label: "是否在线",
        type: "list",
        placeholder: "请选择是否在线",
        span: 7,
        options: [{
          dictLabel: '在线',
          dictValue: '在线'
        }, {
          dictLabel: '离线',
          dictValue: '离线'
        }]
      }],
      searchListFormData: [{
        prop: "wechatGroupName",
        label: "微信群名称",
        placeholder: "请输入微信群名称",
        type: "text",
        isSelect: false,
        span: 18
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      listFormHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => listHandleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => listResetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "导出",
        buttonStyle: "primary",
        className: 'addBtn',
        exp: () => downloadExport()
      }],
      tableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "robotName",
        label: "机器人昵称"
      }, {
        prop: "robotType",
        label: "机器人类型"
      }, {
        prop: "state",
        label: "是否在线"
      }, {
        prop: "robotId",
        label: "机器人编号"
      }, {
        prop: "phone",
        label: "绑定手机号"
      }, {
        prop: "loginData",
        label: "最新登录时间"
      }, {
        prop: "createDateStr",
        label: "创建时间"
      }, {
        prop: "wechatGroupNum",
        label: "管理群数量（个）"
      }, {
        prop: "operation",
        label: "操作",
        width: "200px",
        fixed: "right",
        operation: [{
          name: '查看',
          clickFun: row => showManageList(row)
        }]
      }],
      listTableHeader: [{
        prop: "numberId",
        label: "序号"
      }, {
        prop: "robotName",
        label: "机器人昵称"
      }, {
        prop: "wechatGroupName",
        label: "微信群名称"
      }, {
        prop: "operation",
        label: "操作",
        width: "100px",
        fixed: "right",
        operation: [{
          name: '群消息查阅',
          clickFun: row => showTalkHistory(row)
        }]
      }]
    });
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    onMounted(() => {
      handleQuery();
    });
    /** 查询模型列表 */
    const getList = function () {
      state.loading = true;
      robotApi.robotList(state.queryParams).then(res => {
        if (res.code === 1000) {
          state.list.total = res.data.total || 0;
          state.list.rows = res.data.records || [];
          for (let i in state.list.rows) {
            state.list.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
            let phone = state.list.rows[i]['phone'];
            if (phone != null && phone != '') {
              state.list.rows[i]['phone'] = Decrypt(phone);
            }
          }
        }
      }).finally(() => {
        state.loading = false;
      });
    };
    /** 查询机器人管理微信群列表 */
    const getManageList = function () {
      state.listLoading = true;
      robotApi.robotWeChatGroup(state.listQueryParams).then(res => {
        if (res.code === 1000) {
          state.manageList.total = res.data.total || 0;
          state.manageList.rows = res.data.records || [];
          for (let i in state.manageList.rows) {
            state.manageList.rows[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
          }
        }
      }).finally(() => {
        state.listLoading = false;
      });
    };
    /** 查询管理群列表 */
    const showManageList = function (row) {
      state.listQueryParams.pageSize = 10;
      state.listQueryParams.pageNum = 1;
      state.listQueryParams.wechatGroupName = null;
      state.listQueryParams.robotId = row.robotId;
      getManageList();
      state.dialogVisible = true;
    };
    const showTalkHistory = function (row) {
      state.infoListQueryParams.wechatGroupName = row.wechatGroupName;
      state.infoListQueryParams.robotId = row.robotId;
      state.infoListQueryParams.pageSize = 20;
      state.infoListQueryParams.pageNum = 1;
      state.infoList = [];
      state.infoListToTal = 0;
      state.groupBasicInfo = {
        unit: row.deptName,
        person: row.courtsManageName,
        phone: row.courtsManagePhone,
        groupName: row.wechatGroupName
      };
      getSearchInfo('first');
      state.groupTalkHistoryDialogVisible = true;
    };
    const getSearchInfo = function (val) {
      if (state.infoList.length === state.infoListToTal && state.infoListToTal) {
        return;
      }
      state.infoListLoading = true;
      if (!val) {
        state.infoListQueryParams.pageNum++;
      } else {
        state.infoList = [];
        state.infoListQueryParams.pageNum = 1;
      }
      robotApi.weChatInfo(state.infoListQueryParams).then(res => {
        if (res.code === 1000) {
          state.infoList = state.infoList.concat(res.data.records);
          for (let i in state.infoList) {
            state.infoList[i]['numberId'] = Number(i) + (state.queryParams.pageNum - 1) * state.queryParams.pageSize + 1;
          }
          state.infoListToTal = res.data.total || 0;
        }
      }).finally(() => {
        state.infoListLoading = false;
      });
    };
    /** 导出文件 */
    const downloadExport = function () {
      if (state.isExport) {
        ElMessage.closeAll();
        ElMessage.warning('数据正在导出中，请勿重复操作！');
        return;
      }
      state.isExport = true;
      robotApi.robotExport(state.queryParams).then(res => {
        if (res.status === 200) {
          download(res.data, '机器人列表.xlsx');
        }
        state.isExport = false;
      }).catch(() => {
        state.isExport = false;
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    const listHandleQuery = function () {
      state.listQueryParams.pageNum = 1;
      getManageList();
    };

    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
      handleQuery();
    };

    /** 重置按钮操作 */
    const listResetQuery = function () {
      state.listQueryParams.pageSize = 10;
      state.listQueryParams.pageNum = 1;
      state.listQueryParams.wechatGroupName = null;
      listHandleQuery();
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {}
      });
    };
    const __returned__ = {
      router,
      formContainer,
      state,
      getList,
      getManageList,
      showManageList,
      showTalkHistory,
      getSearchInfo,
      downloadExport,
      handleQuery,
      listHandleQuery,
      resetQuery,
      listResetQuery,
      submitForm,
      ref,
      unref,
      reactive,
      toRefs,
      watch,
      onMounted,
      nextTick,
      get useRouter() {
        return useRouter;
      },
      get ElMessageBox() {
        return ElMessageBox;
      },
      get ElMessage() {
        return ElMessage;
      },
      get formValidate() {
        return formValidate;
      },
      get robotApi() {
        return robotApi;
      },
      get download() {
        return download;
      },
      get Decrypt() {
        return Decrypt;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};