import Cookies from 'js-cookie'
import axios from 'axios'
import querystring from 'querystring'
const TokenKey = 'Admin-Token'
import {  updateToken } from '../api/login'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const staticSettting = {
  clientId: 16001,
  authUrl: `${window.g.VUE_APP_AUTH_URL}`,
  baseUrl: `${window.g.VUE_APP_BASE_URL}`
}
let trustToken = ''
// 获取随机哈希值
export let generateUUID=function() {
  let uuid = '', random;
  for(let i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0;
    if(i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
}

// 获取ticket，传过去的state
export let getTicket = function () {
  console.log(window.location,'window.location')
  let ticketReg = new RegExp('(^|&)' + 'ticket' + '=([^&]*)(&|$)')
  let stateReg = new RegExp('(^|&)' + 'state' + '=([^&]*)(&|$)')
  let ticket = window.location.href.substr(1).match(ticketReg)
  let state = window.location.href.substr(1).match(stateReg)
  if (ticket != null&&state!=null) return decodeURI(ticket[2])+';'+decodeURI(state[2])
  return null
}
// 获取token
export let getTokenParams = function () {
  let reg = new RegExp('(^|#)' + 'access_token' + '=([^&]*)(&|$)')
  let token = window.location.href.substr(1).match(reg)
  if (token != null) return decodeURI(token[2])
  return null
}
// 通过ticket获取token
export let accestokenByTicket = async function () {
  //console.log("进来了-----------")
//   let ticket = getTicket().split(";")[0],
//   state = getTicket().split(";")[1]
//   if (!ticket&&!state) {
//     return false
//   }
//   console.log(ticket,'ticket')
//   console.log(state,'state')
//  await axios.get(
//     staticSettting.authUrl + '/isc_sso/oauth2.0/accessToken',
//     {
//       client_id:staticSettting.clientId,
//       code:ticket,
//       state:state,
//       redirect_uri:staticSettting.baseUrl,
//     }
//   )
  // setToken(res.data.access_token)
  // trustToken = res.data.access_token
  // 响应回来是一个链接，从链接取参数
  //console.log(getTokenParams(),'我是最后的token')
  setToken(getTokenParams())
  if(getTokenParams()!=null) {
     handToken(1)
  }
  return  getTokenParams()
}

export let handToken = function (handType) {
  updateToken({
    token: getToken(),
    handType: handType
  }).then(response => {
    if (response.code === 1000) {
      if(handType===1){
        console.log(response.data,'首次登录把token写入到后端')
      } else if(handType===2) {
        console.log(response.data,'退出清除后端token')
      }

    }
  })
}
export let toLogin = () => {
    window.location.href =
    staticSettting.authUrl+
    '/isc_sso/oauth2.0/authorize?response_type=token&client_id=' +
    staticSettting.clientId +
    '&redirect_uri=' +
    window.location.href
    +'&state=' + generateUUID()
}

export let getRedictUrl = () => {
    return staticSettting.baseUrl
}
